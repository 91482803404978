var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "outter" } },
    [
      _c("fb-view"),
      _c("portal-target", { attrs: { name: "notify" } }),
      _c("portal-target", { attrs: { name: "fav", multiple: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }