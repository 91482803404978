<template>
  <header
    :class="{
      'fb-header': true,
      'van-hairline--bottom': true,
      'fb-header-customer': this.customer,
      'is-fixed': fixed
    }"
  >
    <div class="fb-header-button is-left">
      <slot name="left"></slot>
    </div>
    <div
      class="fb-header-title"
      v-text="title"
      @click="clickTitle"
      v-if="!isdet"
      :style="{ flex: this.customer ? '8' : '1' }"
    ></div>
    <div
      class="fb-header-title"
      v-html="title"
      @click="clickTitle"
      v-else
      :style="{ flex: this.customer ? '8' : '1' }"
    ></div>
    <slot name="copy"></slot>
    <div class="fb-header-button is-right">
      <slot name="right"></slot>
    </div>
    <slot name="customer"></slot>
  </header>
</template>

<script>
/**
 * fb-header
 * @module components/header
 * @desc 顶部导航
 * @param {boolean} [fixed=false] - 固定顶部
 * @param {string} [title] - 标题
 * @param {slot} [left] - 显示在左侧区域
 * @param {slot} [right] - 显示在右侧区域
 *
 * @example
 * <Fb-Header title="我是标题" fixed>
 *   <button slot="left" icon="back" @click="handleBack">返回</button>
 *   <button slot="right" icon="more"></button>
 * </Fb-Header>
 */
export default {
  name: 'fb-header',
  props: {
    fixed: Boolean,
    title: String,
    customer: Boolean,
    isdet: Boolean
  },
  methods: {
    clickTitle() {
      this.$emit('clickTitle')
    }
  }
}
</script>

<style lang="stylus">
@import '~styles/variables.styl'
@import '~styles/mixins'

.fb-header-customer
  background #F8F8F8 !important
  .fb-header-title
    text-align left !important
.fb-header
  align-items center
  background-color $color-white
  box-sizing border-box
  color $color-dark-text
  display flex
  flex-wrap nowrap
  justify-content space-between
  align-items center
  font-size $header-fontSize
  height $header-height
  line-height 1
  padding 0 6px
  position relative
  text-align center
  white-space nowrap
  & .header-menu
    display flex
    & .icon
      display flex
      justify-content center
      align-items center
      height $header-height
      width $headerIconWrapWidth
      & i
        font-size $header-fontSize
.is-fixed
  top 0
  right 0
  left 0
  position fixed
  z-index 100
.fb-header-title
  font-size $header-fontSize
  font-weight normal
  align-items stretch
  line-height $header-height
  padding 0 1px
  flex 1
  ellipsis()
.fb-header-button
  flex 0.16
  button
    padding 0 6px
  &::after
    content none
  &.is-left
    text-align left
  &.is-right
    text-align right
    position relative
    overflow visible
</style>
