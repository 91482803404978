const currson = () => import(/* webpackChunkName: "mapp-currson" */ '@/pages/CaseDetail/components/scrollMenu/components/infoTab/currsonperson')
const othercase = () => import(/* webpackChunkName: "mapp-currson/othercase" */ '@/pages/CaseDetail/components/scrollMenu/components/infoTab/othercase')
const caselaw = () => import(/* webpackChunkName: "mapp-currson/caselaw" */ '@/pages/CaseDetail/components/scrollMenu/components/infoTab/caselaw')
const caseid = () => import(/* webpackChunkName: "mapp-currson/othercase/caseid" */ '@/pages/CaseDetail/components/scrollMenu/components/infoTab/caseid')
export default [{
  path: '/currson',
  name: 'currson',
  component: currson
}, {
  path: '/currson/othercase',
  name: 'othercase',
  component: othercase
}, {
  path: '/currson/caselaw',
  name: 'caselaw',
  component: caselaw
}, {
  path: '/currson/othercase/caseid',
  name: 'caseid',
  component: caseid
}]
