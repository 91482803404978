<template>
  <div class="bottom_tip">
    <div class="nonmember_limit" v-if="visible && !showLimit" @click="openNonmemberDialog">查看全部内容</div>
  </div>
</template>

<script>
export default {
  name: 'bottom-tip',
  props: ['visible', 'showLimit'],
  data() {
    return {}
  },

  methods: {
    // 打开非会员限制弹窗
    openNonmemberDialog() {
      this.$emit('update:showLimit', true)
    },
  },
  mounted() {
    console.log('mounted - nonmemberLimit')
  },
}
</script>

<style lang="stylus" scoped>
.bottom_tip
  margin-top 40px
  height 40px
  width 100%
  line-height 40px
  // border-radius 5px
  & .nonmember_limit
    width 100%
    height 100%
    background rgba(1,1,1,0.1)
    padding 0 20px
    box-sizing border-box
    margin 0 auto
    text-align center
    line-height 40px
    cursor pointer
</style>
