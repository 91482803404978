var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showLimit
      ? _c(
          "div",
          {
            staticClass: "nonmember_dialog",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.closeDialog.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "nonmember_limit" }, [
              _vm.freeze
                ? _c("div", { staticClass: "freeze" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.groupInfo,
                            expression: "groupInfo",
                          },
                        ],
                        staticClass: "join_group",
                      },
                      [
                        _vm._v(
                          "再邀请" +
                            _vm._s(
                              _vm.groupInfo.totalJoinNum - _vm.groupInfo.joinNum
                            ) +
                            "位好友微信参加拼团"
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "btns_area" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.wechatGroup.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("微信邀请")]
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "no_freeze" }, [
                    _vm.isAddEnterpriseWeChatFriend
                      ? _c("div", { staticClass: "invite_vip" }, [
                          _c("div", { staticClass: "invite_vip_title" }, [
                            _vm._v("邀两律师，即可免费一年"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "invite_list" },
                            [
                              _c("img", {
                                staticClass: "item_avatarUrl",
                                attrs: {
                                  src: _vm.calcUrl(_vm.userinfo.avatarUrl),
                                },
                              }),
                              _vm.passInviteList
                                ? [
                                    _c("img", {
                                      staticClass: "item_avatarUrl",
                                      attrs: {
                                        src:
                                          _vm.passInviteList.length > 0
                                            ? _vm.passInviteList[0]
                                            : _vm.baseAvatarUrl,
                                        alt: "",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "item_avatarUrl",
                                      attrs: {
                                        src:
                                          _vm.passInviteList.length > 1
                                            ? _vm.passInviteList[1]
                                            : _vm.baseAvatarUrl,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                : _vm._l([1, 2], function (item) {
                                    return _c("img", {
                                      key: item,
                                      staticClass: "item_avatarUrl",
                                      attrs: {
                                        src: _vm.baseAvatarUrl,
                                        alt: "",
                                      },
                                    })
                                  }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "btns_area" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.wechatInvite.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("微信邀请")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.shortMsgInvite.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("短信邀请")]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tobuy,
                                  expression: "tobuy",
                                },
                              ],
                              staticClass: "tip_text",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toPay.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("直接购买会员")]
                          ),
                        ])
                      : _c("div", { staticClass: "add_customer" }, [
                          _c("div", { staticClass: "customer_title" }, [
                            _vm._v("咨询助理"),
                          ]),
                          _vm.exclusive
                            ? _c("div", { staticClass: "customer_avatar" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.calcUrl(_vm.exclusive.headImgUrl),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "customer_name" }, [
                                  _vm._v(_vm._s(_vm.exclusive.name)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.exclusive
                            ? _c("div", { staticClass: "customer_phone" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-dianhua-copy",
                                  staticStyle: { color: "#00ff7f" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.callPhone(_vm.exclusive.phone)
                                    },
                                  },
                                }),
                                _vm._v("   "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.exclusive.phone)),
                                ]),
                                _vm._v("   "),
                                _c("i", {
                                  staticClass: "iconfont icon-fuzhi1",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.copyPhone(_vm.exclusive.phone)
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "customer_btn" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.wechatAdd.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("微信添加")]
                            ),
                          ]),
                          _c("div", { staticClass: "customer_btn" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toBuyVip.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("购买会员")]
                            ),
                          ]),
                        ]),
                  ]),
            ]),
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.actionNotifyVisible,
                      expression: "actionNotifyVisible",
                    },
                  ],
                  class: { notify: true, [_vm.actionNotify.type]: true },
                },
                [_vm._v(" " + _vm._s(_vm.actionNotify.msg) + " ")]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }