<template>
  <transition name="page-move">
    <div class="ctn-fb-view">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'fb-view'
}
</script>

<style lang="stylus">
.fb-view
  transform translate(0, 0)
.page-move-enter, .page-move-leave-active
  transform translate(100%, 0)
.page-move-enter-active, .page-move-leave-active
  transition transform 0.3s
.ctn-fb-view
  width 100%
  height 100%
</style>
