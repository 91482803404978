var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "page-move" } }, [
    _c(
      "div",
      { staticClass: "ctn-fb-view" },
      [
        _c(
          "keep-alive",
          [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
          1
        ),
        !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }