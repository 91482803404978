const state = {
  reason: undefined,
  wtrType: undefined,
  // 自动添加的第一个当事人默认设置为委托人
  shouldActiveWtrType: true,
  biaoji: false,
  // 用于新建项目的文书
  doc: '',
}

const mutations = {
  // 设置案由
  setReason(state, r) {
    state.reason = r
  },
  // 设置委托人
  setWtrType(state, t) {
    state.wtrType = t
  },
  // 是否需要激活委托人选项
  setShouldActiveWtrType(state, b) {
    state.shouldActiveWtrType = b
  },
  setBiaoji(state, c) {
    state.biaoji = c
  },
  setDoc(state, text) {
    state.doc = text
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
