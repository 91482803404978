// 页面容器
const OA = () => import(/* webpackChunkName: "mapp-oa-wrapper" */ '@/pages/OA')

// 盖章管理
const Stamp = () =>
  import(/* webpackChunkName: "mapp-oa-stamp" */ '@/pages/OA/pages/stampManager')

// 新增盖章
const AddStamp = () =>
  import(/* webpackChunkName: "mapp-oa-addStamp" */ '@/pages/OA/pages/stampManager/addStamp.vue')

// 案件用章
const StampInfo = () =>
  import(/* webpackChunkName: "mapp-oa-stampInfo" */ '@/pages/OA/pages/stampManager/stampInfo.vue')

// 案件管理
const Case = () =>
  import(/* webpackChunkName: "mapp-oa-case" */ '@/pages/OA/pages/caseManager')

// 结案管理
const CloseCase = () =>
  import(/* webpackChunkName: "mapp-oa-closeCase" */ '@/pages/OA/pages/closeCaseManager')
const CaseInfo = () =>
  import(/* webpackChunkName: "mapp-oa-caseInfo" */ '@/pages/OA/pages/closeCaseManager/caseInfo.vue')
const CreateCaseInfo = () =>
  import(/* webpackChunkName: "mapp-oa-createCaseInfo" */ '@/pages/OA/pages/closeCaseManager/createCaseInfo.vue')

// 财务管理
const Finance = () =>
  import(/* webpackChunkName: "mapp-oa-finance" */ '@/pages/OA/pages/financeManager')
// 新增收支
const AddReceive = () =>
  import(/* webpackChunkName: "mapp-oa-addReceive" */ '@/pages/OA/pages/financeManager/addReceive.vue')
// 新增报销
const AddReport = () =>
  import(/* webpackChunkName: "mapp-oa-addReport" */ '@/pages/OA/pages/financeManager/addReport.vue')
// 新增借款
const AddLoan = () =>
  import(/* webpackChunkName: "mapp-oa-addLoan" */ '@/pages/OA/pages/financeManager/addLoan.vue')
// 流程管理
const Flow = () =>
  import(/* webpackChunkName: "mapp-oa-flow" */ '@/pages/OA/pages/flowManager')

// 发票管理
const Invoice = () =>
  import(/* webpackChunkName: "mapp-oa-invoice" */ '@/pages/OA/pages/invoiceManager')

// 开票记录
const BillingRecord = () =>
  import(/* webpackChunkName: "mapp-oa-billingRecord" */ '@/pages/OA/pages/invoiceManager/billingRecord.vue')

// 开票
const addInvoice = () =>
  import(/* webpackChunkName: "mapp-oa-addInvoice" */ '@/pages/OA/pages/invoiceManager/addInvoice.vue')

// 审批管理
const Approve = () =>
  import(/* webpackChunkName: "mapp-oa-Approve" */ '@/pages/OA/pages/approveManager/index.vue')

// 领款
const AddIncome = () =>
  import(/* webpackChunkName: "mapp-oa-AddIncome" */ '@/pages/OA/pages/financeManager/addIncome.vue')

export default [
  {
    path: '/oa/',
    name: 'oa',
    component: OA
  },
  {
    path: '/oa/stamp',
    name: 'oa-stamp',
    component: Stamp
  },
  {
    path: '/oa/addStamp',
    name: 'oa-addStamp',
    component: AddStamp,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/oa/stampInfo',
    name: 'stamp-info',
    component: StampInfo
  },
  {
    path: '/oa/case',
    name: 'oa-case',
    component: Case
  },
  {
    path: '/oa/finance',
    name: 'oa-finance',
    component: Finance
  },
  {
    path: '/oa/addReceive',
    name: 'oa-addReceive',
    component: AddReceive
  },
  {
    path: '/oa/addReport',
    name: 'oa-addReport',
    component: AddReport
  },
  {
    path: '/oa/addLoan',
    name: 'oa-addLoan',
    component: AddLoan
  },
  {
    path: '/oa/closeCase',
    name: 'oa-close-case',
    component: CloseCase
  },
  {
    path: '/oa/caseInfo',
    name: 'oa-caseInfo',
    component: CaseInfo
  },
  {
    path: '/oa/createCaseInfo',
    name: 'oa-createCaseInfo',
    component: CreateCaseInfo
  },
  {
    path: '/oa/flow',
    name: 'oa-flow',
    component: Flow
  },
  {
    path: '/oa/invoice',
    name: 'oa-invoice',
    component: Invoice
  },
  {
    path: '/oa/billingRecord',
    name: 'oa-billing-record',
    component: BillingRecord
  },
  {
    path: '/oa/addInvoice',
    name: 'oa-addInvoice',
    component: addInvoice
  },
  {
    path: '/oa/approve',
    name: 'oa-approve',
    component: Approve
  },
  {
    path: '/oa/addIncome',
    name: 'oa-addIncome',
    component: AddIncome
  }
]
