var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showInviteDialog
    ? _c("div", { staticClass: "new_invite", on: { click: _vm.closeDialog } }, [
        _c("div", { staticClass: "invite_content" }, [
          _c(
            "div",
            {
              staticClass: "invite_vip_title",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.inviteTittle) + " ")]
          ),
          _c(
            "div",
            { staticClass: "invite_list" },
            [
              _vm._l(_vm.passInviteList, function (item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "item_avatarUrl",
                  attrs: { src: item.avatarUrl },
                })
              }),
              _c("img", {
                staticClass: "item_avatarUrl",
                attrs: {
                  src: "https://oss.farbun.com/5dbb8620c07147ffa046aec42966162f",
                  alt: "",
                },
              }),
              _c("div", { staticClass: "item_plus" }, [_vm._v("+")]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "btn_area" },
            [
              _c(
                "van-button",
                {
                  attrs: { size: "small", type: "info" },
                  on: { click: _vm.weChatInvite },
                },
                [_vm._v("微信邀请")]
              ),
              _c(
                "van-button",
                {
                  attrs: { size: "small", type: "info" },
                  on: { click: _vm.shortMsgInvite },
                },
                [_vm._v("短信邀请")]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }