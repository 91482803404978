const state = {
  anaText: '',
  time: 0,
  inSearching: false,
  customerList: [],
  companyList: [],
  lawList: [],
  customerHtml: [],
  pushedDocument: null,
  pushTime: 0,
  currentDocList: [],
  showCurrentDocListTab: true,
  // 当前文书是否未保存
  isCurrentDocumentUnsaved: false,
  // 是否在等待切换tab
  waitChangeTab: false,
  // 是否在切换文书过程中
  inChangeDocProcess: false
}

const mutations = {
  setEditorKV: (state, obj) => {
    const { key, val } = obj
    state[key] = val
  },
  setAnaText: (state, text) => (state.anaText = text),
  setTime: (state, t) => (state.time = t),
  setSuggestionData: (state, data) => (state.suggestionData = data),
  setInSearching: (state, b) => (state.inSearching = b),
  setCustomerHtml: (state, str) => (state.customerHtml = str),
  setCompanyList: (state, d) => (state.companyList = d),
  setCustomerList: (state, d) => (state.customerList = d),
  setLawList: (state, d) => (state.lawList = d),
  setCurrentDocList: (state, d) => (state.currentDocList = d),
  setPushedDocument: (state, d) => (state.pushedDocument = d),
  setPushTime: (state, d) => (state.pushTime = d),
  setShowCurrentDocListTab: (state, d) => (state.showCurrentDocListTab = d)
}

export default {
  namespaced: true,
  state,
  mutations
}
