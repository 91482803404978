const state = {
  caseTitle: '', // 案件名称
  today: Date.now(), // 服务器上时间戳
  caseFilter: '', // 筛选案件
  limit: 10, // 返回条数
  pageNum: 1, // 页码
  TLRange: {}, // 时间轴的事件范围
  TLWidth: 0, // 时间轴的宽度（单位px）
  TLHeight: 0, // 时间轴的高度（单位px）
  TLMode: 'b', // 时间轴的视图模式
  backToTodayTranslateXRem: 1.8, // 回到今天距离时间轴开始位置的x方向偏移值 单位为rem 使用时根据dpr计算出像素
  TLTouching: false, // 标记时间轴是否在拖动中
  TLCanMove: true, // 时间轴是否可以拖动
  docId: 0, // 文书id
  evidenceId: 0, // 证据id
  accidsAndTid: {},
  showHintLineSimple: false, // 显示简单辅助线
  simpleHintlineYData: {}, // 辅助线的样式数据
  showTopItemConnector: false,
  topItemConnnectorPosTime: 0,
  bottomTabMode: 'NORMAL',
  currrentTabName: 'DocumentTab',
  tabAnaSummary: '', // tab中ana检索内容
  tabAnaSuSongQingQiu: '', // tab中ana的诉讼请求
  tabAnaSummaryChange: 0, // 重新检索
  tabAnaSummaryChange2: 0, // 重新检索
  items: '',
  organisename: '', // 组织机构名称
  lawyerid: null, // 组织机构id
  adult: {
    // 法官页面
    sort: [],
    office: '',
    name: '',
    courtId: '',
    caseId: '',
    summary: {},
    judgename: '',
    fbname: '',
    secure: [],
    // sort: this.$store.state.caseDetail.adult.sort,
    portable: [],
    count: ''
  },
  watchButton: true, // 监听保存按钮状态
  lawer: {}, // 保存律师时间
  tabIndex: null
}

const mutations = {
  setorganisename(state, organisename) {
    state.organisename = organisename
  },
  setlawyerid(state, lawyerid) {
    state.lawyerid = lawyerid
  },
  setWatchButton(state, data) {
    state.watchButton = data
  },
  setitems(state, items) {
    state.items = items
  },
  setCaseTitle(state, title) {
    state.caseTitle = title
  },
  setToday(state, today) {
    state.today = today
  },
  setTLRange(state, range) {
    state.TLRange = range
  },
  setTLWidth(state, width) {
    state.TLWidth = width
  },
  setTLHeight(state, height) {
    state.TLHeight = height
  },
  setTLMode(state, mode) {
    state.TLMode = mode
  },
  setTLTouching(state, isTouching) {
    state.TLTouching = isTouching
  },
  setTLCanMove(state, canMove) {
    state.TLCanMove = canMove
  },
  setCaseFilter(state, filters) {
    state.caseFilter = filters
  },
  setPageNum(state, pageNum) {
    state.pageNum = pageNum
  },
  setLimit(state, limit) {
    state.limit = limit
  },
  setDocId(state, dId) {
    state.docId = dId
  },
  setEvidenceId(state, eId) {
    state.evidenceId = eId
  },
  setAccidsAndTid(state, tid) {
    state.accidsAndTid = tid
  },
  setShowHintLineSimple(state, show) {
    state.showHintLineSimple = show
  },
  setSimpleHintlineYData(state, payload) {
    state.simpleHintlineYData = payload
  },
  setShowTopItemConnector(state, show) {
    state.showTopItemConnector = show
  },
  setTopItemConnnectorPos(state, posTime) {
    state.topItemConnnectorPosTime = posTime
  },
  setBottomTabMode(state, mode) {
    state.bottomTabMode = mode
  },
  setCurrentTabName(state, name) {
    state.currrentTabName = name
  },
  setTabAnaSummary(state, abstract) {
    state.tabAnaSummary = abstract
  },
  setTabAnaSuSongQingQiu(state, abstract) {
    state.tabAnaSuSongQingQiu = abstract
  },
  setTabIndex(state, d) {
    state.tabIndex = d
  }
}

const getters = {
  getTLRangeStamp: state => {
    const start = new Date(state.TLRange.start).getTime()
    const end = new Date(state.TLRange.end).getTime()
    return { start, end }
  },
  getTLRangeCrossNumber: state => {
    const range = new Date(state.TLRange.end) - new Date(state.TLRange.start)
    return range
  },
  getBackToTodayTranslateX: state => {
    const fontSize = document.documentElement.style.fontSize.replace(/px/g, '')
    return state.backToTodayTranslateXRem * fontSize
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
