<template>
  <div class="download-app-comp" @touchend="toDownloadPage">
    <div class="app-logo">
      <i class="iconfont icon--"></i>
    </div>
    <div class="info">
      <p class="title">{{ title }}</p>
      <p class="sub-title">{{ subTitle }}</p>
    </div>
    <div class="download-icon">
      <i class="iconfont icon-download"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'download-app',
  props: {
    title: {
      type: String,
      default: _ => '律呗-智能法律服务'
    },
    subTitle: {
      type: String,
      default: _ => '查看更多功能请下载App或打开网址farbun.com'
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    toDownloadPage() {
      location.href = 'https://download.farbun.com'
    }
  },
  mounted() {},
  created() {}
}
</script>

<style scoped lang="stylus">
.download-app-comp
  display flex
  width 100%
  height 64px
  align-items center
  background rgba(0,0,0, 0.6)
  padding 0 10px
  & .app-logo
    width 44px
    height 44px
    color #fff
    background #3f9eff
    border-radius 3px
    display flex
    align-items center
    justify-content center
    & i
      font-size 36px
  & .info
    color #fff
    padding 0 5px
    flex 1
    & .title
      font-size 14px
    & .sub-title
      font-size 12px
  & .download-icon
    width 44px
    height 44px
    border-radius 50%
    background #ff9a01
    display flex
    align-items center
    justify-content center
    & i
      color #fff
      font-size 20px
</style>
