const BigData = () =>
  import(/* webpackChunkName: "mapp-page-bigdata" */ '@/pages/BigData')

const LawPage = () =>
  import(/* webpackChunkName: "mapp-page-law-page" */ '@/pages/BigData/pages/law/route-param')

export default [
  {
    path: '/searchkeepalive/search',
    name: 'bigdata',
    component: BigData,
    alias: '/search',
    meta: {
      fn: 'clickback',
      isPublic: true
    }
  },
  {
    path: '/search/law/:id',
    name: 'bigdataLawPage',
    component: LawPage,
    meta: {
      fn: 'clickback',
      isPublic: true
    }
  }
]
