const state = {
  remind: false,
  status: null,
  nodeData: null,
  remindData: null,
  remindFormUpdata: 0
}
const mutations = {
  // 打开表单
  openRemindForm (state, { status, nodeData, remindData }) {
    state.remind = true
    state.status = status
    state.nodeData = nodeData
    state.remindData = remindData
  },
  // 关闭表单
  closeRemindForm (state) {
    state.status = null
    state.nodeData = null
    state.remind = false
    state.remindData = null
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}
