const state = {
  Options: {
    scrollHeight: 0, // 滚动条高度
    is_added: false, // 数据
    pagenum: 0 // 页数
  }
}

export default {
  state
}
