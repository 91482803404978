var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("portal", { attrs: { to: "notify" } }, [
        _c(
          "div",
          { staticClass: "fb-notify", style: _vm.styleComputed },
          [
            _vm.isTextMsg
              ? [_c("span", { class: [_vm.type] }, [_vm._v(_vm._s(_vm.msg))])]
              : [
                  _c("span", [_vm._v(_vm._s(_vm.msg.text))]),
                  _c(
                    "span",
                    { staticClass: "link", on: { click: _vm.onLinkClick } },
                    [_vm._v(_vm._s(_vm.msg.link))]
                  ),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }