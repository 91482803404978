const Login = () =>
  import(/* webpackChunkName: "mapp-user-login" */ '@/pages/UserCenter/Login')

const MyDocument = () =>
  import(/* webpackChunkName: "mapp-user-mydocument" */ '@/pages/UserCenter/MyDocument')

const wenshumodel = () =>
  import(/* webpackChunkName: "mapp-user-wenshumodel" */ '@/pages/UserCenter/MyDocument/wenshumodel')

const userInfo = () =>
  import(/* webpackChunkName: "mapp-user-userInfo" */ '@/pages/UserCenter/userInfo')
const myShare = () =>
  import(/* webpackChunkName: "mapp-user-myShare" */ '@/pages/UserCenter/myShare')
const myRegister = () => import('@/pages/UserCenter/myRegister')
const myBuy = () => import('@/pages/UserCenter/myBuy')

export default [
  {
    path: '/user/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user/mydocument',
    name: 'MyDocument',
    component: MyDocument
  },
  {
    path: '/user/wenshumodel',
    name: 'wenshumodel',
    component: wenshumodel
  },
  {
    path: '/user/userInfo',
    name: 'userInfo',
    component: userInfo
  },
  {
    path: '/user/myShare',
    name: 'myShare',
    component: myShare
  },
  {
    path: '/user/mybuy',
    name: 'mybuy',
    component: myBuy
  },
  {
    path: '/user/myRegister',
    name: 'myRegister',
    component: myRegister
  }
]
