var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "fb-button",
      class: [
        "fb-button--" + _vm.type,
        "fb-button--" + _vm.size,
        {
          "is-disabled": _vm.disabled,
          "is-plain": _vm.plain,
        },
      ],
      attrs: { type: _vm.nativeType, disabled: _vm.disabled },
      on: { click: _vm.handleClick },
    },
    [
      _vm.icon || _vm.$slots.icon
        ? _c(
            "span",
            { staticClass: "fb-button-icon" },
            [
              _vm._t("icon", function () {
                return [
                  _vm.icon
                    ? _c("i", {
                        staticClass: "iconfont",
                        class: "icon-" + _vm.icon,
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("label", { staticClass: "fb-button-text" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }