var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fb-invite" }, [
    _vm.showHeadAndHistory
      ? _c("div", { staticClass: "head" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "right-close", on: { click: _vm.emitClose } },
            [_c("i", { staticClass: "iconfont icon-clear" })]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "info", class: _vm.showHeadAndHistory ? "" : "nohead" },
      [_vm._m(1), _vm._m(2)]
    ),
    _vm.showHeadAndHistory
      ? _c("div", { staticClass: "history" }, [
          _c("div", { staticClass: "history-title" }, [_vm._v("邀请记录")]),
          _c(
            "ul",
            { staticClass: "list-body" },
            [
              _vm._m(3),
              _vm._l(_vm.inviteArr, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "item-content item" },
                  [
                    _c("div", { staticClass: "item-avatarUrl" }, [
                      _c("img", { attrs: { src: item.avatarUrl, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "item-nickName" }, [
                      _vm._v(_vm._s(item.nickName)),
                    ]),
                    _c("div", { staticClass: "flex5" }, [
                      _vm._v(_vm._s(item.statelabel)),
                    ]),
                    _c("div", { staticClass: "flex3" }, [
                      _vm._v(_vm._s(item.time)),
                    ]),
                  ]
                )
              }),
              _vm._m(4),
            ],
            2
          ),
          _c("div", { staticClass: "btn" }, [
            _c(
              "div",
              { staticClass: "invite-btn", on: { click: _vm.toInvite } },
              [
                _vm._v(" 立即邀请 "),
                _c("div", { staticClass: "invite-tip" }, [
                  _vm._m(5),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.lastNum < 3,
                          expression: "lastNum < 3",
                        },
                      ],
                    },
                    [_vm._v("再邀请" + _vm._s(_vm.lastNum) + "名立得vip")]
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "form-title" }, [
            _vm._v(_vm._s(_vm.sendUser.sendUserNickname) + " 向您发送一个求助"),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c("div", { staticClass: "content-title" }, [
              _vm._v(" 完成注册 助力 "),
              _c("span", { staticClass: "lawyer-name" }, [
                _vm._v(_vm._s(_vm.sendUser.sendUserNickname)),
              ]),
              _vm._v(" 得律呗vip "),
            ]),
            _c("div", { staticClass: "form-item" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regForm.nickName,
                    expression: "regForm.nickName",
                  },
                ],
                attrs: { type: "text", placeholder: "请输入您的姓名" },
                domProps: { value: _vm.regForm.nickName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.regForm, "nickName", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-item" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regForm.phone,
                    expression: "regForm.phone",
                  },
                ],
                attrs: { type: "text", placeholder: "请输入您的手机号" },
                domProps: { value: _vm.regForm.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.regForm, "phone", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "code-item" }, [
              _c("div", { staticClass: "form-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.regForm.refCode,
                      expression: "regForm.refCode",
                    },
                  ],
                  attrs: { type: "text", placeholder: "输入验证码" },
                  domProps: { value: _vm.regForm.refCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.regForm, "refCode", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("span", { staticClass: "code", on: { click: _vm.sendCode } }, [
                _vm._v(
                  _vm._s(_vm.canSend ? "获取验证码" : "还剩" + _vm.time + "秒")
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "btn" }, [
            _c(
              "div",
              { staticClass: "register-btn", on: { click: _vm.toRegister } },
              [_vm._v("立即注册")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left-logo" }, [
      _c("img", {
        attrs: {
          src: require("../../../../public/img/text_logo.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-title" }, [
      _c("div", [_vm._v("邀请好友助力")]),
      _c("div", { staticClass: "text-shandow" }, [_vm._v("开通律呗仅需三步")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-steps" }, [
      _c("div", { staticClass: "step one" }, [
        _c("div", { staticClass: "step-icon" }, [
          _c("i", { staticClass: "iconfont icon-share-2" }),
        ]),
        _c("span", { staticClass: "step-text" }, [_vm._v("分享活动")]),
      ]),
      _c("div", { staticClass: "step-to-step" }, [
        _c("i", { staticClass: "iconfont icon-more" }),
        _c("i", { staticClass: "iconfont icon-gengduo" }),
      ]),
      _c("div", { staticClass: "step two" }, [
        _c("div", { staticClass: "step-icon" }, [
          _c("i", { staticClass: "iconfont icon-fankui" }),
        ]),
        _c("span", { staticClass: "step-text" }, [_vm._v("接受邀请")]),
      ]),
      _c("div", { staticClass: "step-to-step" }, [
        _c("i", { staticClass: "iconfont icon-more" }),
        _c("i", { staticClass: "iconfont icon-gengduo" }),
      ]),
      _c("div", { staticClass: "step three" }, [
        _c("div", { staticClass: "step-icon" }, [
          _c("i", { staticClass: "iconfont icon-shenfen" }),
        ]),
        _c("span", { staticClass: "step-text" }, [_vm._v("完成注册")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item-head item" }, [
      _c("div", { staticClass: "flex4" }, [_vm._v("好友信息")]),
      _c("div", { staticClass: "flex5" }),
      _c("div", { staticClass: "flex3" }, [_vm._v("时间")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item-empty item" }, [
      _c("div", { staticClass: "item-plus" }, [_c("div", [_vm._v("+")])]),
      _c("div", { staticClass: "item-uninvite" }, [_vm._v("待邀请")]),
      _c("div", { staticClass: "flex5" }),
      _c("div", { staticClass: "flex3" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sanjiao" }, [
      _c("i", { staticClass: "iconfont icon-right-flag" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }