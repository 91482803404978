var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "fbLoading",
    },
    [
      _c("van-loading"),
      _vm.text
        ? _c("div", { staticClass: "loading-text" }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }