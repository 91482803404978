const state = {
  oaTab: '',
  oaType: '',
  filterKV: null
}

const mutations = {
  setOaTab(state, val) {
    state.oaTab = val
  },
  setOaType(state, val) {
    state.oaType = val
  },
  setFilters(state, val) {
    state.filterKV = val
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
