const Client = () => import(/* webpackChunkName: "mapp-client" */ '@/pages/Client')
const UploadFile = () => import(/* webpackChunkName: "mapp-client" */ '@/pages/Client/pages/uploadFileClient')
const added = () => import(/* webpackChunkName: "mapp-client-added" */ '@/pages/Client/pages/added')
export default [
  {
    path: '/client',
    name: 'Client',
    component: Client,
  },
  {
    path: '/client/added',
    name: 'added',
    component: added,
  },
  {
    path: '/uploadFileClient',
    name: 'uploadFileClient',
    component: UploadFile,
  },
]
