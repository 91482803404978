/* eslint-disable */
import { caseBase } from '~api-config'
import vm from '@/main'
import axios from 'axios'
const state = {
  userId: null,
  tabNum: 3, // tab选中的索引
  isSearch: false, // 搜索框
  history: [], // 面包屑导航
  contentData: [], // 主体数据
  zhishishu_loading: false, // 知识树loading
  searchNum: 0, // 放置给搜索生成的 -id
  alertState: {
    show: false,
  }, // 弹出框的状态
  docTree: null, // 系统知识 我的知识的文书树
  showTree: false,
  anjiandangan: [], // 案件档案的数据
  caseAlert: false, // 案件档案的弹出框
  caseTag: [], // 案件的当事人
}
const mutations = {
  setTabNum(state, index) {
    state.tabNum = index
  },
  setIsSearch(state) {
    state.isSearch = !state.isSearch
  },
  setContentData(state, data) {
    state.contentData = data
  },
  setHistory(state, { data, type }) {
    state.history = [
      ...state.history.map((item) =>
        item.active
          ? {
              ...item,
              active: !item.active,
            }
          : item
      ),
      data,
    ]
    if (type) {
      state.history = [data]
    }
  },
  putHistory(state, id) {
    const index = state.history.findIndex((item) => item.id === id)
    const arr = state.history.filter((...args) => args[1] <= index)
    arr[arr.length - 1].active = true
    state.history = arr
  },
  setLoading(state, bool) {
    state.zhishishu_loading = bool
  },
  setSearchNum(state) {
    state.searchNum = state.searchNum - 1
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setAlertState(state, data) {
    state.alertState = data
  },
  setDocTree(state, data) {
    state.docTree = data
  },
  setShowTree(state, data) {
    state.showTree = data
  },
  setAnjiandangan(state, data) {
    state.anjiandangan = data
  },
  setCaseAlert(state, data) {
    state.caseAlert = data
  },
  setCaseTag(state, data) {
    state.caseTag = data
  },
}
const actions = {
  // 点击那四个
  async getSystemTreeList({ commit, dispatch, state }, { id, pId, name, file }) {
    // 不管是面包屑的丝点击还是 对应的点击 都做一次计算
    commit('putHistory', id)
    commit('setShowTree', false)
    if (id === 0) {
      commit('setLoading', true)
      const response = await axios.get(caseBase + '/farbun/systemTree/top')
      commit('setLoading', false)
      const { systemTree } = response.data.data
      const result = systemTree.map((item) => {
        item.renderType = {
          leftIcon: 'shop',
          rightIcon: false,
        }
        return item
      })
      commit('setContentData', result)
    } else if (id < 0) {
      dispatch('getSearchData', {
        name: name.split('>')[1],
        id,
      })
    } else if (!file) {
      commit('setLoading', true)
      let response = null
      const wodezhishi = `${caseBase}/farbun/myTree/list?userId=${state.userId}&pId=${id}`
      const zitongzhishi = `${caseBase}/farbun/systemTree/list/${id}`
      if (state.tabNum === 2) {
        response = await axios.get(wodezhishi)
      } else if (state.tabNum === 3) {
        response = await axios.get(zitongzhishi)
      }
      commit('setLoading', false)
      const { children } = response.data.data
      dispatch('mapResult', children)
      // 点击到了 文件 要展示文件的操作
    } else if (file) {
      commit('setShowTree', true)
      const response = await axios.get(`${caseBase}/farbun/systemTree/findLevelContentById?documentId=${id}`)
      const { datas } = response.data.data
      commit('setDocTree', [datas])
    }
  },
  // 搜索
  async getSearchData({ dispatch, commit, state }, { name, id, userId }) {
    commit('setSearchNum')
    commit('setLoading', true)
    let response = null
    const xitongzhishi = `${caseBase}/farbun/systemTree/search?title=${name}`
    const wodezhishi = `${caseBase}/farbun/myTree/search?title=${name}&userId=${state.userId}`
    if (state.tabNum === 3) {
      response = await axios.get(xitongzhishi)
    } else {
      response = await axios.get(wodezhishi)
    }
    commit('setLoading', false)
    // if (response.data.data) {
    const obj = {
      id: id || state.searchNum,
      name: '>' + name,
      active: true,
    }
    commit('setHistory', {
      data: obj,
    })
    commit('putHistory', id || state.searchNum)
    const { data } = response.data
    if (data) {
      dispatch('mapResult', data.children)
    } else {
      commit('setContentData', [])
    }
    // }
  },
  // 数据统一做处理
  mapResult({ commit }, data) {
    const result = data.map((item) => ({
      ...item,
      renderType: {
        // 判断是不是文件夹
        leftIcon: item.file === 0 ? 'pending-payment' : 'pending-orders',
        rightIcon: true,
      },
    }))
    commit('setContentData', result)
  },
  async getFindCases({ commit, state }, { id, types, name, userId }) {
    commit('putHistory', id)
    commit('setCaseTag', [])
    if (id === -1) {
      console.log(name)
      const res = await axios.get(`${caseBase}/design/fileManage/like?userId=${state.userId}&fileName=${name.split('>')[1]}`)
      const { fileModels } = res.data.data
      const data = fileModels.map((item) => {
        return {
          name: item.fileName,
          types: 3,
          ...item,
        }
      })
      commit('setAnjiandangan', data)
    }
    if (types === 0) {
      commit('setLoading', true)
      const response = await axios.get(`${caseBase}/farbun/other/getPerson?userId=${state.userId}&permissionValue=caseDoc:read`)
      const { group } = response.data.data
      const data = group.map(({ name, rightIcon, leftIcon, userId }) => {
        return {
          id: userId,
          name,
          rightIcon,
          leftIcon,
          types: 1,
          userId,
        }
      })
      commit('setAnjiandangan', data)
      commit('setLoading', false)
    }
    if (types === 1) {
      commit('setLoading', true)
      const response = await axios.get(`${caseBase}/farbun/other/findCases?userId=${id}`)
      const { timeline } = response.data.data
      const result = timeline.map((item) => {
        let types = null
        // 历史案件
        if (item.model === 'historyCases') {
          types = '2-2'
        }
        if (item.model === 'timeline') {
          types = '2-1'
        }
        return {
          types,
          userId,
          ...item,
        }
      })
      commit('setAnjiandangan', result)
      commit('setLoading', false)
    }
    if (types === '2-1') {
      commit('setLoading', true)
      const response = await axios.get(`${caseBase}/design/fileManage/cs/${id}?userId=${userId}`)
      const { files, litigants } = response.data.data
      const data = files.map((item) => {
        return {
          name: item.fileName,
          types: 3,
          ...item,
        }
      })
      commit('setAnjiandangan', data)
      commit('setLoading', false)
      commit('setCaseTag', litigants)
    }
    if (types === '2-2') {
      commit('setLoading', true)
      const response = await axios.get(`${caseBase}/farbun/other/fhyc/${id}`)
      const { data } = response.data
      const result = data.map((item) => {
        return {
          types: 'history',
          ...item,
        }
      })
      commit('setAnjiandangan', result)
      commit('setLoading', false)
    }
    if (types === 3) {
      commit('setLoading', true)
      const response = await axios.get(`${caseBase}/design/fileManage/p/${id}`)
      const { files } = response.data.data
      const data = files.map((item) => {
        return {
          name: item.fileName,
          types: 3,
          ...item,
        }
      })
      commit('setAnjiandangan', data)
      commit('setLoading', false)
    }
    if (types === 'history') {
      vm.$router.push(`/precedentDetail?id=${id}`)
    }
  },
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
