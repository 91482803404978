<template>
  <div id="outter">
    <fb-view></fb-view>
    <portal-target name="notify"></portal-target>
    <portal-target name="fav" multiple></portal-target>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'
export default {
  name: 'app-entry',
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    ...mapActions('user', ['getOrganizationData']),
    handleRefreshPage(payload) {
      const { path } = this.$route
      if (payload && path.includes('search')) {
        window.location.reload()
      }
    },
  },
  mounted() {
    // 获取组织机构信息
    if (this.userInfo && this.userInfo.id) {
      this.getOrganizationData()
    }
    const { name } = this.$bridge._onReceiveNotification
    const evt = {
      name,
      handler: this.handleRefreshPage,
    }
    this.$useBridge(evt, this)
  },
  async created() {
    // 分析功能中渲染图表会报错，修改方法
    Vue._watchers = Vue.prototype._watchers = []

    window.History = this.$router
    window.goback = () => {
      if (this.$route.meta.fn && typeof this.$store.state.user.currentObj === 'object') {
        if (typeof this.$store.state.user.currentObj[this.$route.meta.fn] === 'function') {
          // web自定义关闭方法
          this.$store.state.user.currentObj[this.$route.meta.fn](arguments)
          return
        }
      }
      if (this.$router.length > 1) {
        this.$router.back()
      } else {
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.back()
        } else if (ua === 'fb-iOS-Webview') {
          window.webkit.messageHandlers.back.postMessage({})
        } else if (ua === 'fb-flutter-Webview') {
          try {
            window.back.postMessage('{}')
          } catch (error) {
            console.log(error, 'back.postMessage')
          }
        } else {
          this.$router.back()
        }
      }
    }
  },
}
</script>
<style lang="stylus">
*
  -webkit-overflow-scrolling touch
#outter
  width 100%
  height 100%
</style>
