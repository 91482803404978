<template>
  <div class="under-dev">
    <div class="icon">
      <van-icon name="info-o" />
    </div>
    <div class="text">
      开发中
    </div>
  </div>
</template>

<script>
export default {
  name: 'under-dev'
}
</script>

<style lang='stylus'>
.under-dev
  width 100%
  padding 20px
  .icon, .text
    display flex
    justify-content center
    align-items center
    height 40px
    color #666
    i
      font-size 40px
      color #ff976a
</style>
