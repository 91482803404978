<template>
  <div class="fb-page" :style="bgColor">
    <div class="top">
      <slot name="top"></slot>
    </div>
    <slot name="header"></slot>
    <div class="main-wrapper">
      <main class="content">
        <slot>{{ content }}</slot>
      </main>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'fb-page',
  props: {
    desc: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    bgColor: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
.hello
  position fixed
  z-index 200000
  bottom 0
.fb-page
  position absolute
  z-index 10
  top 0
  left 0
  width 100%
  height 100%
  overflow auto
  .header
    position relative
    height 44px
    line-height 44px
    text-align center
    background-color #edf0f4
    box-shadow 0 1px 6px #ccc
    -webkit-backface-visibility hidden
    backface-visibility hidden
    z-index 5
  >.main-wrapper
    // height: calc(100% - 44px)
    // height: -webkit-calc(100% - 44px)
    // overflow: auto
    // -webkit-overflow-scrolling: touch
    padding-top 44px
    .content
      margin 0px
</style>
