<template>
  <div>
    <div v-if="showLimit" class="nonmember_dialog" @click.stop="closeDialog">
      <div class="nonmember_limit">
        <div v-if="freeze" class="freeze">
          <div v-show="groupInfo" class="join_group">再邀请{{ groupInfo.totalJoinNum - groupInfo.joinNum }}位好友微信参加拼团</div>
          <div class="btns_area">
            <!-- <img
            id="copyImgId"
            :src="`data:image/png;base64,${miniQRCode}`"
            style="width: 100%"
            alt=""
          /> -->
            <div class="btn" @click.stop="wechatGroup">微信邀请</div>
          </div>
        </div>
        <div v-else class="no_freeze">
          <div v-if="isAddEnterpriseWeChatFriend" class="invite_vip">
            <div class="invite_vip_title">邀两律师，即可免费一年</div>
            <div class="invite_list">
              <img class="item_avatarUrl" :src="calcUrl(userinfo.avatarUrl)" />
              <template v-if="passInviteList">
                <img class="item_avatarUrl" :src="passInviteList.length > 0 ? passInviteList[0] : baseAvatarUrl" alt="" />
                <img class="item_avatarUrl" :src="passInviteList.length > 1 ? passInviteList[1] : baseAvatarUrl" alt="" />
              </template>
              <template v-else>
                <img v-for="item in [1, 2]" :key="item" class="item_avatarUrl" :src="baseAvatarUrl" alt="" />
              </template>
              <!-- <img
              v-for="(item, index) in passInviteList"
              :key="index"
              class="item_avatarUrl"
              :src="item.avatarUrl"
            />
            <div class="item_plus">+</div> -->
            </div>
            <div class="btns_area">
              <div class="btn" @click.stop="wechatInvite">微信邀请</div>
              <div class="btn" @click.stop="shortMsgInvite">短信邀请</div>
            </div>
            <div class="tip_text" v-show="tobuy" @click.stop="toPay">直接购买会员</div>
          </div>
          <!-- <div v-else class="get_customer">
          <div class="get_customer_title">
            <span>添加你的专属助理开通律呗</span>
            <span>免费使用</span>
          </div>
          <div class="btns_area">
            <div class="btn" @click.stop="wechatAdd">微信添加</div>
          </div>
          <div class="customer_area">
            <div class="customer_title">专属助理</div>
            <div class="customer_info" v-if="exclusive">
              <img class="customer_avatar" :src="exclusive.headImgUrl" />
              <span class="customer_name">{{ exclusive.name }}</span>
              <span class="customer_phone">
                <i
                  class="iconfont icon-dianhua-copy"
                  @click.stop="callPhone(exclusive.phone)"
                ></i
                >&nbsp;&nbsp; <span>{{ exclusive.phone }}</span
                >&nbsp;&nbsp;
                <i
                  class="iconfont icon-fuzhi1"
                  @click.stop="copyPhone(exclusive.phone)"
                ></i>
              </span>
            </div>
          </div>
        </div> -->
          <div v-else class="add_customer">
            <!-- <div class="customer_title">加客服，可免费使用一年</div> -->
            <div class="customer_title">咨询助理</div>
            <div v-if="exclusive" class="customer_avatar">
              <img :src="calcUrl(exclusive.headImgUrl)" alt="" />
              <!-- {{ exclusive.headImgUrl.replace('http', 'https') }} -->
              <div class="customer_name">{{ exclusive.name }}</div>
            </div>
            <div v-if="exclusive" class="customer_phone">
              <i class="iconfont icon-dianhua-copy" style="color: #00ff7f" @click.stop="callPhone(exclusive.phone)"></i>&nbsp;&nbsp; <span>{{ exclusive.phone }}</span
              >&nbsp;&nbsp;
              <i class="iconfont icon-fuzhi1" @click.stop="copyPhone(exclusive.phone)"></i>
            </div>
            <div class="customer_btn">
              <div class="btn" @click.stop="wechatAdd">微信添加</div>
            </div>
            <div class="customer_btn">
              <div class="btn" @click.stop="toBuyVip">购买会员</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 操作提示 -->
      <transition name="fade">
        <div :class="{ notify: true, [actionNotify.type]: true }" v-show="actionNotifyVisible">
          {{ actionNotify.msg }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
// import QR from 'qrcodejs2'
export default {
  name: 'fb-nonmember',
  props: ['showLimit'],
  created() {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isiOS) {
      this.tobuy = false
    } else {
      this.tobuy = true
    }
  },
  data() {
    return {
      //  dialogVisible: false,
      freeze: false,
      qrcode: null,
      inviteText: '',
      actionNotify: {
        msg: '收藏成功',
        type: 'success',
      },
      actionNotifyVisible: false,
      baseAvatarUrl: 'https://oss.farbun.com/user/16803/icon/471349363722031104/headImg',
      isResources: false,
      isShow: false,
      userData: '',
      tobuy: false,
    }
  },
  computed: {
    ...mapState('user', ['userinfo', 'userinfo']),
    ...mapGetters('nonmemberLimit', ['pid']),
    ...mapState('nonmemberLimit', ['inviteList', 'promotionRecordId', 'hasGetGroupinfo', 'groupInfo', 'miniQRCode', 'exclusive']),
    isAddEnterpriseWeChatFriend() {
      if (this.userinfo) {
        return this.userinfo && this.userinfo.isAddEnterpriseWeChatFriend === 1
      }
      return this.userinfo && this.userinfo.isAddEnterpriseWeChatFriend === 1
    },
    // 认证律师的邀请用户
    passInviteList() {
      if (this.inviteList) {
        return this.inviteList.filter((v) => v.isAuthenticationLawyer === 1).map((h) => h.avatarUrl)
      } else {
        return []
      }
    },
    inviteTittle() {
      // console.log(this.passInviteList, '认证律师的邀请用户')
      if (this.passInviteList.length === 0) {
        return '邀两名律师'
      } else if (this.passInviteList.length === 1) {
        return '再邀一名律师开通'
      } else {
        return '邀请律师'
      }
    },
    /* shareUrl() {
      const list = JSON.stringify(this.passInviteList)
      let shareUrl = ''
      if (this.$base === 'https://api.farbun.com') {
        shareUrl = `https://tool.farbun.com/transform/shareInvite?id=${
          this.promotionRecordId
        }&list=${list}`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/shareInvite?id=${
          this.promotionRecordId
        }&list=${list}`
      }
      return shareUrl
    }, */
    ua() {
      return navigator.userAgent
    },
  },
  methods: {
    calcUrl(url) {
      if (url.indexOf('https') !== -1) return url
      if (url.indexOf('http') !== -1) return url.replace('http', 'https')
    },
    ...mapMutations('user', ['setuserinfo']),
    ...mapMutations('nonmemberLimit', ['SET_INVITE_LIST', 'SET_INVITE_CODE', 'SET_MINI_QRCODE', 'SET_EXCLUSIVE', 'SET_GROUP_INFO']),
    // 获取用户信息
    // 显示操作提示
    showActionSuccessNotify() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.actionNotifyVisible = true
      this.timer = setTimeout(() => {
        this.actionNotifyVisible = false
      }, 1000)
    },
    // 获取助力列表
    async getInviteList() {
      try {
        const res = await this.$axios.post(`${this.$fissionBase}/fission/promotionAttentionRecord/list`)
        if (res.data && res.data.code === '200') {
          this.SET_INVITE_LIST(res.data.data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 获取邀请promotionRecordId
    async getInviteCode() {
      console.log('获取邀请二维码')
      let promotionRecordId = ''
      const { data } = await this.$axios.post(`${this.$fissionBase}/fission/promotionRecord/generateInvitation`)
      if (data.code === '200' && data.data) {
        promotionRecordId = data.data.promotionRecordId
        this.SET_INVITE_CODE(promotionRecordId)
        // this.initQRcode()
      }
    },
    // 微信邀请
    wechatInvite() {
      let shareUrl = ''
      if (this.$base === 'https://api.farbun.com') {
        shareUrl = `https://tool.farbun.com/transform/shareInvite/${this.promotionRecordId}`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/shareInvite/${this.promotionRecordId}`
      }
      const title = `${this.userinfo.nickName}邀请您共同开通律呗`
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.shareCaseFileWithDes(title, '律呗', window.encodeURI(shareUrl))
      } else if (this.ua === this.$ua.i) {
        // ios环境
        // eslint-disable-next-line
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: title,
            description: '律呗',
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.actionNotify = {
          msg: '网页端暂不支持分享',
          type: 'error',
        }
        this.showActionSuccessNotify()
        // this.$notify('网页端暂不支持分享')
        console.log(this.inviteTittle, shareUrl)
      }
    },
    // 短信邀请
    shortMsgInvite() {
      let shareUrl = ''
      if (this.$base === 'https://api.farbun.com') {
        shareUrl = `https://tool.farbun.com/transform/shareInvite/${this.promotionRecordId}?openId=${this.userinfo.openid}&unionId=${this.userinfo.unionid}&isAlreadyRegister=true`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/shareInvite/${this.promotionRecordId}?openId=${this.userinfo.openid}&unionId=${this.userinfo.unionid}&isAlreadyRegister=true`
      }
      const text = '您的好友邀请您一起免费使用：' + window.encodeURI(shareUrl)
      window.console.log('shareUrl', shareUrl, text)
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.showCustomSendSmsView(this.promotionRecordId, text)
      } else {
        this.actionNotify = {
          msg: '该设备暂不支持此功能',
          type: 'error',
        }
        this.showActionSuccessNotify()
      }
    },
    // 直接购买 - 调用原生去团购界面
    toPay() {
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toVipRechargeView()
      } else if (this.ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      } else {
        // this.$notify('该设备暂不支持此功能')
        this.actionNotify = {
          msg: '该设备暂不支持此功能',
          type: 'error',
        }
        this.showActionSuccessNotify()
      }
      this.closeDialog()
    },
    // 进入小程序订单页面分享给好友一起拼单
    wechatGroup() {
      console.log('进入小程序订单页面分享给好友一起拼单')
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toGroupPaymentWxMiniProgram(this.pid)
      } else {
        // this.$notify('该设备暂不支持此功能')
        this.actionNotify = {
          msg: '该设备暂不支持此功能',
          type: 'error',
        }
        this.showActionSuccessNotify()
      }
    },
    // 复制电话号码
    copyPhone(text) {
      console.log('复制电话号码:', text)
      const textArea = document.createElement('textarea')
      textArea.value = text || this.exclusive.phone
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$notify({
      //   type: 'success',
      //   message: '内容已复制',
      // })
      this.actionNotify = {
        msg: '内容已复制',
        type: 'success',
      }
      this.showActionSuccessNotify()
    },
    // 打电话
    callPhone(phone) {
      console.log('打电话:', phone)
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // 安卓环境
          // eslint-disable-next-line
          AND2JS.call(phone)
        } else if (u === 'fb-iOS-Webview') {
          // ios环境
          // eslint-disable-next-line
          window.webkit.messageHandlers.backToTool.postMessage({
            takePhoneNumber: phone,
          })
        } else if (u === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          window.backToTool.postMessage(JSON.stringify({ takePhoneNumber: phone }))
        } else {
          // this.$notify('非原生环境,返回原生指定页')
          this.actionNotify = {
            msg: '非原生环境',
            type: 'error',
          }
          this.showActionSuccessNotify()
        }
      } catch (err) {
        console.log(err, 'backToTool.postMessage')
      }
    },
    // 购买vip
    toBuyVip() {
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toVipRechargeView({})
      } else if (this.ua === this.$ua.i) {
        // ios
        window.webkit.messageHandlers.toVipRechargeView.postMessage({})
      } else if (this.ua === 'fb-flutter-Webview') {
        window.toVipRechargeView.postMessage('{}')
      }
    },
    // 进入小程序客服页面识别二维码加客服微信
    wechatAdd() {
      const token = this.$store.getters['user/getUserToken']
      window.console.log('进入小程序客服页面识别二维码加客服微信', token)
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toKeFuWxMiniProgram(token)
      } else if (this.ua === this.$ua.i) {
        // ios
        window.webkit.messageHandlers.toKeFuWxMiniProgram.postMessage(token)
      } else if (u === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        window.toKeFuWxMiniProgram.postMessage(token)
      } else {
        // this.$notify('该设备暂不支持此功能')
        this.actionNotify = {
          msg: '该设备暂不支持此功能',
          type: 'error',
        }
        this.showActionSuccessNotify()
      }
    },
    initQRcode() {
      // if (this.qrcode) return
      // eslint-disable-next-line no-unused-vars
      // this.qrcode = new QR('qrcodePanel', {
      //   width: 150,
      //   height: 150, // 高度
      //   text: this.shareUrl, // 二维码内容
      // })
      // this.inviteText = this.shareUrl
    },
    // 获取当前用户团购情况
    async getGroupInfo() {
      console.log('---------')
      try {
        const res = await this.$axios.post(`${this.$base}/management/order/queryGroupOrder`)
        if (res.data && res.data.code === 200) {
          if (res.data.data) {
            this.freeze = true
            this.SET_GROUP_INFO(res.data.data)
            return
          } else {
            this.freeze = false
          }
        } else {
          this.freeze = false
        }
        this.SET_GROUP_INFO()
      } catch (err) {
        console.log(err)
      }
    },
    // 获取专属客服
    async getExclusive() {
      try {
        const res = await this.$axios.post(`${this.$fissionBase}/fission/customerService/queryExclusive`)
        if (res.data.code === '200') {
          this.SET_EXCLUSIVE(res.data.data)
          console.log(this.exclusive)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 获取团购小程序二维码
    async getGroupMiniQrcode() {
      try {
        const result = await this.$axios.post(`${this.$fissionBase}/fission/wechat/genderGroupOrderMiniQRCode`, { pid: this.pid })
        console.log(result, '---------》》result')
        if (result.data.code === '200') {
          this.SET_MINI_QRCODE(result.data.data)
        } else {
          this.SET_MINI_QRCODE(null)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.qrcode = null
      this.$emit('update:showLimit', false)
    },
    async getInitData() {
      this.initUserData()
      if (!this.showLimit) return
      await this.initUserData()
      if (this.userinfo && this.userinfo.memberStatus === 1) {
        this.$emit('update:showLimit', false)
      }
      // 团购
      if (!this.pid) {
        if (!this.hasGetGroupinfo) {
          await this.getGroupInfo()
        } else {
          this.freeze = false
        }
      } else {
        this.freeze = true
      }
      /* if (this.freeze && !this.miniQRCode) {
        await this.getGroupMiniQrcode()
      } */
      if (!this.freeze) {
        if (!this.isAddEnterpriseWeChatFriend) {
          // 添加客服
          if (!this.exclusive) {
            // 专属客服
            this.getExclusive()
          }
        } else {
          // 邀请注册
          if (!this.inviteList) {
            console.log('nonmember-limit -邀请注册')
            this.getInviteList()
          }
          if (!this.promotionRecordId) {
            this.getInviteCode()
          } else {
            // this.$nextTick(() => {
            //   this.initQRcode()
            // })
          }
        }
      }
    },
    // 查看是否有资源
    initUserData() {
      if (this.userinfo?.isAddEnterpriseWeChatFriend === 1) {
        if (this.userinfo.isValid === true && this.isAddEnterpriseWeChatFriend) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      }
    },
  },
  mounted() {
    this.getInitData()
  },
  watch: {
    showLimit(val, ol) {
      this.getInitData()
    },
  },
}
</script>

<style lang="stylus">
.nonmember_dialog
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  position fixed
  top 0px
  left 0
  z-index 1999
  overflow auto
  & .nonmember_limit
    padding 20px
    box-sizing border-box
    position relative
    background #fff
    width 80%
    margin 0 auto
    top 20%
    border-radius 5px
    & .freeze,.no_freeze
      line-height 40px
      display flex
      flex-direction column
      align-items center
      & .invite_list
        display flex
        height 60px
        align-items center
        & .item_plus
          width 40px
          height 40px
          border-radius 50%
          font-size 30px
          text-align center
          line-height 40px
          color #666666
          border 1px solid #666666
          margin-left 10px
        & .item_avatarUrl
          width 40px
          height 40px
          border-radius 50%
          border 1px solid #ccc
          margin 0 10px 0
          overflow hidden
          box-sizing border-box
      & .add_customer
        width 100%
        & .customer_title
          width 100%
          height 30px
          font-size 20px
          text-align center
          font-weight bold
          color orange
          line-height 30px
        & .customer_avatar
          width 100%
          margin-top 10px
          position relative
          & img
            width 100%
          & .customer_name
            position absolute
            left 8px
            bottom 20px
            background rgba(254,254,254,.2)
            border 1px solid #fff
            line-height 24px
            padding 3px 6px
            color #fff
            border-radius 2px
            box-sizing border-box
        & .customer_phone
            width 100%
            display flex
            font-size 16px
            line-height 20px
            & .icon-dianhua-copy
              color cyan
        & .customer_btn
          margin-top 10px
          width 100%
          display flex
          justify-content center
          align-items center
          .btn
            width 80px
            height 40px
            border-radius 3px
            line-height 40px
            text-align center
            color #ffffff
            background #409eff
            margin 0 10px
      & .invite_group,.get_customer,.invite_vip,.join_group
        font-weight bold
        color orange
        text-align center
        cursor pointer
      & .get_customer
        width 100%
        & .get_customer_title
          display flex
          align-items center
          flex-direction column
          line-height 24px
      & .code_area
        .qrcode
          margin 10px auto
          width 150px
          height 150px
          background-color red
          img
            width 100%
            height 100%
      & .btns_area
        margin-top 20px
        width 100%
        display flex
        justify-content center
        align-items center
        .btn
          width 80px
          height 40px
          border-radius 3px
          line-height 40px
          text-align center
          color #ffffff
          background #409eff
          margin 0 10px
      & .tip_text
        color orange
        line-height 40px
        text-align left
        cursor pointer
      & .customer_area
        color #333
        & .customer_title
          text-align left
        & .customer_info
          display flex
          align-items center
          & .customer_avatar
            width 36px
            height 36px
            border-radius 50%
            border 1px solid #ccc
            overflow hidden
            box-sizing border-box
          & .customer_name
            width 60px
            margin 0 10px
          & .customer_phone
            flex 1
            & .icon-dianhua-copy
              color cyan
</style>
<style lang="stylus">
.notify
  position fixed
  top 0
  left 0
  width 100vw
  margin 0 auto
  z-index 99999
  box-sizing border-box
  color #fff
  font-size 0.4rem
  line-height 1.2rem
  text-align center
  transition-duration 0.2s
  &.success
    background #67C23A
  &.error
    background #ee0a24
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
