import 'babel-polyfill'
import Vue from 'vue'
import usePlugin from './usePlugin'
import qiniu from './components/uploadFile/index'
import { BRIDGE_FUNCTIONS_FOR_NATIVE, hookBridgeFunc, releaseBridgeFunc, useBridge } from '~src/bridge'
import store from './store'
import router from './router'
import { baseURL, pydataBase, fissionBase } from '@api-config'
// import FastClick from 'fastclick'
import App from './App.vue'
import axios from '@/api'
import { EventBus, useEventBus } from '@/common/eventBus'
import { history } from './api/history'
// import openRouteNames from '@/common/openRouteNames'
import promise from 'es6-promise'
import { Step, Steps } from 'vant'
import './components'

// 样式
import 'styles/reset.css'
import 'styles/border.css'
import 'styles/global.styl'
import 'styles/iconfont.css'
import 'styles/styleSheet.styl'
import 'quill/dist/quill.core.css'

// 移动端控制台

// import VConsole from 'vconsole'
// const i = new VConsole()
// console.log(i)

promise.polyfill()

usePlugin(Vue)
const iOSUA = 'fb-iOS-Webview'
const AndroidUA = 'fb-Android-Webview'
const flutterUA = 'fb-flutter-Webview'
const timeout = 60000
// axios全局配置
axios.defaults.timeout = timeout

// 关闭Vue生产环境部署提示
Vue.config.productionTip = false

// history对象控制器
// eslint-disable-next-line new-cap
const his = new history(router)

// 过滤重定向路由
his.AddFilter('/')

// ios登录后信息
window.getLoginUserInfoFromIos = function (data) {
  if (!data) return
  const userObj = JSON.parse(data)
  console.log('1', userObj)
  const { userJson, token } = userObj
  const userInfo = JSON.parse(userJson)
  store.commit('user/resumeLogin', {
    userInfo: userInfo,
    token,
    userinfoAll: userObj,
  })
}
// Flutter登录后信息
window.getLoginUserInfoFromFlutter = function (data) {
  if (!data) return
  const userObj = JSON.parse(data)
  console.log('2', userObj)
  const { userJson, token } = userObj
  const userInfo = JSON.parse(window.atob(userJson))
  store.commit('user/resumeLogin', {
    userInfo: userInfo,
    token,
    userinfoAll: userObj,
  })
}
// 兼容旧版
window.getLoginInfoFromIos = function (data) {
  if (!data) return
  const userInfo = JSON.parse(data)
  console.log('3', userInfo)
  const token = userInfo.token.token
  console.log('getLoginInfoFromIos', userInfo, token)
  store.commit('user/resumeLogin', {
    userInfo: userInfo.user,
    token,
    userinfoAll: userInfo,
  })
}

// ios登录设备版本信息
window.getDeviceInfoFromIos = function (data) {
  console.log('getDeviceInfoFromIos', data)
  store.commit('user/setLoginVersion', data)
}

// 将UA标识符挂载到vue原型上以便于访问
const uaEnum = {
  Android: AndroidUA,
  iOS: iOSUA,
  Web: 'web',
  a: AndroidUA,
  i: iOSUA,
  w: 'web',
}

Vue.prototype.$axios = axios
Vue.prototype.$http = axios.create()
Vue.prototype.$bus = new Vue()
Vue.prototype.$base = baseURL
Vue.prototype.$fissionBase = fissionBase
Vue.prototype.$pydataBase = pydataBase
Vue.prototype.$ua = uaEnum
Vue.prototype.$bridge = BRIDGE_FUNCTIONS_FOR_NATIVE
Vue.prototype.$hook = hookBridgeFunc
Vue.prototype.$unhook = releaseBridgeFunc
Vue.prototype.$bus = EventBus
Vue.prototype.$usesEventBus = useEventBus
Vue.prototype.$useBridge = useBridge

// 挂载七牛云SDK
qiniu.prototype.setParams(axios, store)
Vue.prototype.$qiniu = qiniu

export function getUserInfoFromNative() {
  // 判断程序执行环境  安卓端 fb-Android-Webview | iOS端 fb-iOS-Webview
  const ua = navigator.userAgent
  console.log('判断环境', ua)
  store.commit('user/setUserAgent', ua)
  // const userInfo = store.state.user.userInfo
  // const token = store.state.user.token
  // if (token && userInfo && JSON.stringify(userInfo) !== '{}') return
  if (ua === iOSUA) {
    try {
      window.webkit.messageHandlers.getDeviceInfo.postMessage({})
      //  获取用户新信息
      window.webkit.messageHandlers.getLoginUserInfo.postMessage({})
      // 兼容旧版
      window.webkit.messageHandlers.getLoginInfo.postMessage({})
    } catch (err) {
      console.log('从iOS端获取数据发生错误', err)
    }
  } else if (ua === AndroidUA) {
    try {
      // eslint-disable-next-line
      const deviceInfo = AND2JS.getDeviceInfo()
      store.commit('user/setLoginVersion', deviceInfo)
      // eslint-disable-next-line
      const infoStringV2 = AND2JS.getLoginUserInfo()
      if (infoStringV2) {
        const userObj = JSON.parse(infoStringV2)
        console.log('4', userObj)
        const { userJson, token } = userObj
        const userInfo = JSON.parse(userJson)
        store.commit('user/resumeLogin', {
          userInfo,
          token,
          userinfoAll: userObj,
        })
        return
      }
    } catch (err) {
      console.log('从安卓端获取用户数据v2发生错误', err)
    }
    try {
      // eslint-disable-next-line
      // const infoStringOld = AND2JS.getLoginInfo()
      const infoStringOld = window.getLoginInfo.postMessage()
      // 兼容旧版安卓获取用户信息方法
      if (infoStringOld) {
        const info = JSON.parse(infoStringOld)
        console.log('5', info)
        const { user, token } = info
        if (user && token.token) {
          store.commit('user/resumeLogin', {
            userInfo: user,
            token: token.token,
            userinfoAll: info,
          })
        }
      }
    } catch (err) {
      console.log('从安卓端获取用户数据v1发生错误', err)
    }
  } else if (ua === flutterUA) {
    console.log('确定是flutter环境')
    try {
      window.getDeviceInfo.postMessage('{}')
      //  获取用户新信息
      window.getLoginUserInfo.postMessage('{}')
      // 兼容旧版
      window.getLoginInfo.postMessage('{}')
    } catch (err) {
      console.log('从flutter获取数据发生错误getLoginInfo.postMessage,getLoginUserInfo.postMessage,getDeviceInfo.postMessage', err)
    }
  } else {
    const ls = window.localStorage
    const ss = window.sessionStorage
    const lsUser = ls ? ls.getItem('userInfo') : null
    const ssUser = ss ? ss.getItem('userInfo') : null
    if (ssUser || lsUser) {
      const u = ssUser || lsUser
      const storeUserInfo = JSON.parse(u)
      console.log('6', storeUserInfo)
      const token = ss.getItem('token') || ls.getItem('token') || ''
      if (token) {
        store.commit('user/resumeLogin', {
          userInfo: storeUserInfo,
          token,
        })
      }
    }
  }
}
getUserInfoFromNative()

// 路由拦截
router.beforeEach((to, from, next) => {
  console.log('to', to)
  if ((to.name === 'Ana' || to.name === 'bigdata') && to.fullPath.includes('?')) {
    store.commit('user/setToken', to.query.token)
    next()
    return
  }
  // 记录路由走向
  if (to.query.KeepAlive === 'KeepAlive') {
    to.meta.keepAlive = true
  } else {
    to.meta.keepAlive = false
  }
  const ua = navigator.userAgent
  if (ua === AndroidUA || ua === iOSUA || ua === flutterUA) {
    getUserInfoFromNative()
    next()
  } else {
    const storeUserInfo = store.getters['user/getUserInfo']
    const storeUserToken = store.getters['user/getUserToken']
    const hasToken = storeUserToken && storeUserToken !== ''
    const hasUserInfo = storeUserInfo && JSON.stringify(storeUserInfo) !== '{}'
    // 路由名称不在开放路由数组里 即需要登录
    // const notOpenRoutes = openRouteNames.indexOf(to.name) === -1

    // 如果取不到用户信息和token信息且不是开放页面 则跳转到登录页
    // if ((!hasUserInfo || !hasToken) && notOpenRoutes) {
    //   console.log('如果取不到用户信息和token信息且不是开放页面 则跳转到登录页')
    //   // getUserInfoFromNative()
    // } else
    if (hasUserInfo && hasToken) {
      store.commit('user/resumeLogin', {
        userInfo: storeUserInfo,
        token: storeUserToken,
      })
    }
    next()
  }
})

Vue.use(Step)
Vue.use(Steps)

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

export default app
