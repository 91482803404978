const state = {
  token: '',
  caseName: '',
  click: '',
  summary: '', // 案由
  text: '', // 输入框的值
  changeCaseData: [], // changeCase 输入框的值
  screenConditionData: {}, // 筛选数据
  screenSelect: [], // 筛选选中
  anaTwoKeyDown: [], // 关键字
  semanticData: '', // semantic 数据
  // 争议焦点的内容
  focusText: ''
}
const mutations = {
  // 设置summary的值
  SET_SUMMARY(state, val) {
    state.summary = val
  },
  SET_TEXT(state, val) {
    state.text = val
  },
  SET_ANATWOKEYDOWN(state, val) {
    state.anaTwoKeyDown = val
  },
  SET_CHANGECASEDATA(state, val) {
    state.changeCaseData = val
  },
  SET_SCREENCONDITIONDATA(state, val) {
    state.screenConditionData = val
  },
  SET_SEMANTICDATA(state, val) {
    state.semanticData = val
  },
  SET_SCREENSELECT(state, val) {
    state.screenSelect = val
  },
  // 简单的修改store中的值
  SET_ANA_KV: (state, obj) => {
    const { key, val } = obj
    state[key] = val
  },
  SET_FOCUS_TEXT: (state, val) => {
    state.focusText = val
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
