export const history = (function () {
  history.prototype.__init__ = function () {
    // 创建历史路由记录
    this.historylist = []
    // 规则集
    this.rules = []
    // 监听回退变化
    window.addEventListener('popstate', () => {
      this.__historycallback__()
    }, false)
  }

  // 监听变化发布事件
  history.prototype.__historycallback__ = function (e) {
    this.historylist.splice(this.getLength() - 2, 2)
  }

  // 路由发展守护函数
  history.prototype._pathrecord_ = function (path) {
    const index = this.rules.findIndex((item) => {
      return item.fullPath === path.fullPath
    })
    if (index > -1) {
      return 'The path that does not conform to the rules'
    } else if (typeof this.historylist[this.getLength() - 1] === 'object') {
      if (path.fullPath === this.historylist[this.getLength() - 1].fullPath) {
        return 'The SamePage'
      }
    }
    this.historylist.push(path)
    return 'Record Success'
  }

  // 获取路由长度
  history.prototype.getLength = function () {
    return this.historylist.length
  }

  // 添加规则集
  history.prototype.AddFilter = function (path) {
    this.rules.push(path)
  }

  // 定义模拟History对象
  function history (router) {
    // 初始化函数
    this.__init__()
    // 将history对象挂载到Vue-router下
    router.__defineGetter__('length', () => {
      return this.getLength()
    })
    router.__defineGetter__('History', () => {
      return this.historylist
    })
  }

  return history
})()
