// / Knowledge 知识树
const Knowledge = () =>
  import(/* webpackChunkName: "home-casedetail-Knowledge" */ '@/pages/CaseDetail/pages/Knowledge')
const pageDetail = () =>
  import(/* webpackChunkName: "home-casedetail-Knowledge-DetailPage" */ '@/pages/CaseDetail/pages/Knowledge/components-detail/DetailPage')

export default [
  {
    name: 'Knowledge',
    path: '/knowledge',
    component: Knowledge,
    children: [
      {
        name: 'knowledge-pageDetail',
        path: '/knowledge/detailPage',
        component: pageDetail
      }
    ]
  }
]
