// const userManuals = () => import(/* webpackChunkName: "mapp-userManuals" */ '@/pages/userManuals/index')
const helpcenter = () =>
  import(/* webpackChunkName: "mapp-helpcenter" */ '@/pages/helpcenter/index')
export default [
  {
    path: '/userManuals',
    name: 'userManuals',
    component: helpcenter,
    mata: {
      isPublic: true
    }
  }
]
