import { baseURL } from './config'
import axios from 'axios'
import { typeOf } from '@/utils/assist'
import store from '../store'
import { Dialog } from 'vant'
import router from '../router'
import { getUserInfoFromNative } from '@/main.js'

async function postFeedback($axios, content, user) {
  // content参数应该为数组
  let c = content
  if (typeOf(content) === 'array') {
    c = JSON.stringify(content)
  } else {
    c = JSON.stringify([content])
  }

  const params = { content: c }
  params.clientType = store.state.user.version || window.navigator.userAgent || 'mobile'

  if (user) {
    params.userId = user.id
    params.nickname = user.nickName
    params.account = user.account
    params.province = user.province
    params.city = user.city
  }

  $axios.post(`${baseURL}/admin/feedback`, params).catch((err) => Promise.reject(err))
}

axios.interceptors.request.use(
  (config) => {
    const token = store.state.user?.token || ''
    console.log('token', token)
    token && (config.headers.common['Authentication-Token'] = token)
    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('统一错误处理', error)
    const url = error.config.url
    const message = error.message
    const code = error.code
    const stack = error.stack && error.stack.substring(0, 100)
    const user = store.state.user.userInfo
    const fbUrl = 'admin/feedback'
    // 网络错误
    const isDisconnection = message === 'Network Error'

    // 调用反馈接口
    const postError = () => {
      // 排除feeback接口和网络错误 防止循环调用
      if (url.indexOf(fbUrl) === -1 && !isDisconnection) {
        postFeedback(axios, [{ content: { message, url, code, stack }, type: 'json' }], user)
      }
    }

    if (error.response) {
      const code = error.response.status && parseInt(error.response.status)
      if (code === 403) {
        goLawerAuthPage()
      } else if (code === 401) {
        getUserInfoFromNative()
        // router.push('/user/login')
        // alert('打开页面出错[401]')
      } else {
        postError()
      }
    } else {
      postError()
    }
  }
)

const goLawerAuthPage = () => {
  Dialog.confirm({
    title: '律师认证',
    message: '无法查询到默认律师 是否进行律师认证?',
    confirmButtonText: '立即认证',
    cancelButtonText: '以后再说',
  })
    .then(() => {
      router.push({
        path: '/lawyerAuth',
      })
    })
    .catch(() => {
      // on cancel
    })
}

export default axios
