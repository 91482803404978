const state = {
  nodeform: false, // 弹出框开关
  formType: '', // 弹出框类型 lian pizhun zhencha
  nodeFormType: '', // 表单类型
  formStatus: 0, // 表单状态
  startDate: 0, // 开始时间
  deadline: null, // 截止时间 默认必须是null 应为要判断是否有值来确定初始日期计算方式 要和后端返回保持一致都用null
  dateDelay: 0, // 时间差数值
  dateType: '', // 时间差单位类型
  dateKind: 'date', // timepicker的类型  date datetime
  constractor: '', // 承办人
  court: null, // 法院 要用null存法院id
  fayuanName: '', // 法院名称
  caseId: null,
  formId: null,
  nodeId: null,
  textArray: [{
    isChecked: 0,
    content: '',
    status: 1
  }], // 多行文本
  cooperation: [], // 协助人添加
  showCoopForm: false,
  title: '', // 标题
  dateNow: 0,
  loadingFinish: false,
  outsideCooperation: [], // outside添加协作人
  showQuestion: false, // 展示问号表单
  nodeIdAndName: [], // 特殊表单id
  procedureType: null, // 程序类型
  juZhengEndDate: null, // 举证日期（表单中节点）
  daBianEndDate: null, // 答辩日期（表单中节点）
  kaiTingEndDate: null // 开庭日期（表单中节点）
}
const mutations = {
  // 设置表单对象值
  setFormKV: (state, obj) => {
    state[obj.key] = obj.val
  },
  openNodeForm: (state) => (state.nodeform = true),
  closeNodeForm: (state) => (state.nodeform = false),
  openCoopForm: (state) => (state.showCoopForm = true),
  closeCoopForm: (state) => (state.showCoopForm = false),
  setCooperation: (state, d) => (state.cooperation = d),
  nodeformShow: function (state) {
    state.nodeform = true
  },
  nodeformReset: function (state) {
    state.nodeform = false
    state.formType = null
    state.formStatus = null
    state.dateDelay = 0
    state.startDate = null
    state.deadline = null
    state.dateType = ''
    state.dateKind = 'date'
    state.constractor = ''
    state.court = null
    state.caseId = null
    state.formId = null
    state.nodeId = null
    state.textArray = [{
      isChecked: 0,
      content: '',
      status: 1
    }]
    state.title = ''
    state.loadingFinish = false
    state.nodeIdAndName = []
    state.procedureType = null
    state.juZhengEndDate = null
    state.daBianEndDate = null
    state.kaiTingEndDate = null
  },
  addMore (state, index) {
    state.textArray.splice(index + 1, 0, {
      isChecked: 0,
      content: '',
      status: 1
    })
  },
  removeCell (state, index) {
    state.textArray[index].status = 0
  },
  showNodeForm (
    state, {
      type,
      startDate,
      caseId,
      nodeId,
      title,
      showQuestion = false
    }) {
    if (type === 'NOFORM') return
    // 表单类型

    state.nodeFormType = type
    // 表单时间
    state.startDate = startDate
    // caseId
    state.caseId = caseId
    // nodeId
    state.nodeId = nodeId
    // title
    state.title = title
    // 是否展示问号表单
    state.showQuestion = showQuestion
    state.nodeform = true
  },
  setFormType: (state, d) => (state.formType = d),
  setDataKind: (state, d) => (state.dataKind = d),
  setStartDate: (state, d) => (state.startDate = d),
  setDateType: (state, d) => (state.dateType = d),
  setDateDelay: (state, d) => (state.dateDelay = d),
  setFormStatus: (state, d) => (state.formStatus = d),
  setConstractor: (state, d) => (state.constractor = d),
  setTextArray: (state, d) => (state.textArray = d),
  setCourt: (state, d) => (state.court = d),
  setIsNull: (state, d) => (state.isNull = d),
  setFayuanName: (state, d) => (state.fayuanName = d),
  setDeadline: (state, d) => (state.deadline = d),
  setFormId: (state, d) => (state.formId = d),
  setOutsideCooperation: (state, d) => (state.outsideCooperation = d),
  setLoadingFinish: (state, d) => (state.loadingFinish = d)
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}
