/**
 * 用于#资讯搜索# 标签拖拽功能
 */

const state = {
  tempText: '',
}

const mutations = {
  SET_TEMPTEXT: (state, visible) => {
    state.tempText = visible
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
