const Customer = () =>
  import(/* webpackChunkName: "pcapp-customer" */ '@/pages/Customer')
export default [
  {
    path: '/customer/:caseId',
    name: 'customer',
    component: Customer,
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
    }
  }
]
