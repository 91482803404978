var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "download-app-comp", on: { touchend: _vm.toDownloadPage } },
    [
      _vm._m(0),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("p", { staticClass: "sub-title" }, [_vm._v(_vm._s(_vm.subTitle))]),
      ]),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "app-logo" }, [
      _c("i", { staticClass: "iconfont icon--" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "download-icon" }, [
      _c("i", { staticClass: "iconfont icon-download" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }