var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "under-dev" }, [
    _c(
      "div",
      { staticClass: "icon" },
      [_c("van-icon", { attrs: { name: "info-o" } })],
      1
    ),
    _c("div", { staticClass: "text" }, [_vm._v(" 开发中 ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }