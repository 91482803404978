import Vue from 'vue'
import Vuex from 'vuex'
import caseIndex from './modules/caseIndex'
import caseDetail from './modules/caseDetail'
import user from './modules/user'
import nodeform from './modules/nodeform'
import createCase from './modules/createCase'
import remindForm from './modules/remindForm'
import zhishishu from './modules/zhishishu'
import taskData from './modules/taskData'
import search from './modules/search'
import client from './modules/client'
import editor from './modules/editor'
import ana from './modules/ana'
import dnd from './modules/dnd'
import oa from './modules/oa'
import searchReport from './modules/searchReport'
import nonmemberLimit from './modules/nonmemberLimit'
import dingjuan from './modules/dingjuan'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    caseIndex,
    caseDetail,
    user,
    nodeform,
    createCase,
    remindForm,
    zhishishu,
    taskData,
    search,
    client,
    editor,
    ana,
    dnd,
    searchReport,
    oa,
    nonmemberLimit,
    dingjuan,
  },
})

export default store
