const CommonDocument = () =>
  import(/* webpackChunkName: "mapp-DocumentHub-CommonDocument" */ '@/pages/DocumentHub/CommonDocument')

const LitigationDocument = () =>
  import(/* webpackChunkName: "mapp-DocumentHub-LitigationDocument" */ '@/pages/DocumentHub/LitigationDocument')

export default [
  {
    path: '/documenthub/common',
    name: 'CommonDocument',
    component: CommonDocument
  },
  {
    path: '/documenthub/litigation',
    name: 'LitigationDocument',
    component: LitigationDocument
  }
]
