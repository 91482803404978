import VueQuillEditor from 'vue-quill-editor'
import Meta from 'vue-meta'
import PortalVue from 'portal-vue'
import {
  ActionSheet,
  Button,
  Tag,
  Toast,
  Dialog,
  DatetimePicker,
  Popup,
  Field,
  Icon,
  Loading,
  Cell,
  CellGroup,
  Collapse,
  Checkbox,
  CheckboxGroup,
  CollapseItem,
  Picker,
  Search,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Radio,
  RadioGroup,
  Switch,
  SwipeCell,
  TreeSelect,
  List,
  ImagePreview,
  PullRefresh,
  Notify,
  Uploader,
  Area,
  DropdownMenu,
  DropdownItem,
  Sticky,
  Calendar,
  NavBar,
  NumberKeyboard,
  Popover,
  Empty,
  Overlay,
  Form,
} from 'vant'

const usePlugin = (Vue) => {
  Vue.use(VueQuillEditor)
    .use(ActionSheet)
    .use(Meta)
    .use(Toast)
    .use(Button)
    .use(Dialog)
    .use(DatetimePicker)
    .use(Popup)
    .use(Field)
    .use(Icon)
    .use(Loading)
    .use(Cell)
    .use(CellGroup)
    .use(Collapse)
    .use(CollapseItem)
    .use(Picker)
    .use(Search)
    .use(Tab)
    .use(Tabs)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(RadioGroup)
    .use(Radio)
    .use(Switch)
    .use(SwipeCell)
    .use(TreeSelect)
    .use(List)
    .use(ImagePreview)
    .use(PullRefresh)
    .use(Notify)
    .use(Uploader)
    .use(Area)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Sticky)
    .use(Calendar)
    .use(NavBar)
    .use(NumberKeyboard)
    .use(Popover)
    .use(Tag)
    .use(Empty)
    .use(PortalVue)
    .use(Overlay)
    .use(Form)
  Vue.prototype.$dialog = Dialog
}

export default usePlugin
