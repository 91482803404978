import { baseURL } from '~api-config'
import axios from 'axios'
const state = () => ({
  searchCollectionId: null, // 搜索报告父文件夹id
  judgeReports: [],
  lawReports: [],
  criterionReports: [], // 标准
  knowledgeReports: [], // 知识
  agreementReports: [], // 合同 - 移动端不添加只展示和页面修改
  contentReports: [], // 正文
  anaReports: [], // 分析中加入检索报告的内容
  textReport: '', // 文本报告
  // tempReport: null, // 临时检索报告
  ordinaryReport: null, // 最近一条普通检索报告
  // contentCount: 0, // 正文报告所有子项的个数
  clearCheck: false, // 是否清空所有正文的内容
  lawId: null, // 法律id
})
// eslint-disable-next-line no-unused-vars
let reportTimer = null
const mutations = {
  SET_CLEAR_CHECK(state, flag) {
    state.clearCheck = flag
  },
  // 临时检索报告父文件夹id
  SET_SEARCH_COLLECTION_ID(state, id) {
    state.searchCollectionId = id
  },
  // 设置临时检索报告
  // SET_TEMP_REPORT(state, report) {
  //   state.tempReport = report
  // },
  // 设置最近一条普通检索报告
  SET_NEW_ORDINARY_REPORT(state, report) {
    /* if (report && report.isDeletable) {
      // 最新一条是临时普通检索报告
      state.ordinaryReport = report
    } else {
      // 最新一条是临时检索报告
      state.ordinaryReport = null
    } */
    state.ordinaryReport = report
  },
  // 设置文本报告
  SET_TEXT_REPORT(state, textReport) {
    state.textReport = textReport
  },
  // 设置正文报告
  SET_CONTENT_REPORT(state, items) {
    state.contentReports = JSON.parse(JSON.stringify(items))
  },
  // 设置判例报告
  SET_JUDGE_REPORT(state, items) {
    state.judgeReports = JSON.parse(JSON.stringify(items))
  },
  // 设置法律报告
  SET_LAW_REPORT(state, items) {
    state.lawReports = JSON.parse(JSON.stringify(items))
  },
  // 设置知识报告
  SET_KNOWLEDGE_REPORT(state, items) {
    state.knowledgeReports = JSON.parse(JSON.stringify(items))
  },
  SET_AGREEMENT_REPORT(state, items) {
    state.agreementReports = [...items]
  },
  // 设置标准报告
  SET_CRITERION_REPORT(state, items) {
    state.criterionReports = JSON.parse(JSON.stringify(items))
  },
  // 设置分析报告
  SET_ANA_REPORT(state, items) {
    state.anaReports = items
  },
  SET_LAW_ID(state, id) {
    state.lawId = id
  },
}
const actions = {
  // 获取最新一条报告
  getStampReport({ state, commit }, { userId, callback }) {
    const url = `${baseURL}/management/user/${userId}/collection?page=0&size=1&type=SEARCHREPORT&isShow=1`
    axios
      .get(url)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          const { content } = res.data.data
          if (content && content.length) {
            const reportData = content[0]
            const dataContent = JSON.parse(reportData.content) || null
            commit('SET_NEW_ORDINARY_REPORT', reportData)
            console.log('获取临时文件 》》》》》》》》》》', reportData, dataContent)
            if (dataContent) {
              commit('SET_KNOWLEDGE_REPORT', dataContent.knowledgeReports)
              commit('SET_AGREEMENT_REPORT', dataContent.agreementReports)
              commit('SET_CRITERION_REPORT', dataContent.criterionReports)
              commit('SET_JUDGE_REPORT', dataContent.judgeReports)
              commit('SET_CONTENT_REPORT', dataContent.contentReports)
              commit('SET_ANA_REPORT', dataContent.anaReports)
              commit('SET_LAW_REPORT', dataContent.lawReports)
              commit('SET_TEXT_REPORT', dataContent.textReport)
            } else {
              commit('SET_KNOWLEDGE_REPORT', [])
              commit('SET_AGREEMENT_REPORT', [])
              commit('SET_CRITERION_REPORT', [])
              commit('SET_JUDGE_REPORT', [])
              commit('SET_CONTENT_REPORT', [])
              commit('SET_ANA_REPORT', [])
              commit('SET_LAW_REPORT', [])
              commit('SET_TEXT_REPORT', '')
            }
          }
        }
        callback && callback()
      })
      .catch((err) => {
        console.log(err)
        callback && callback()
      })
  },
  // 新增/修改一条报告
  setStampReport({ state, commit }, { userId, type, reportData, title, label, isAdd, callback, timeout }) {
    let content = {
      judgeReports: state.judgeReports,
      lawReports: state.lawReports,
      contentReports: state.contentReports,
      anaReports: state.anaReports,
      criterionReports: state.criterionReports,
      knowledgeReports: state.knowledgeReports,
      agreementReports: state.agreementReports,
      textReport: state.textReport,
    }
    if (type && reportData) {
      if (type === 'judgeReports') {
        content.judgeReports = reportData
        commit('SET_JUDGE_REPORT', reportData)
      } else if (type === 'lawReports') {
        content.lawReports = reportData
        commit('SET_LAW_REPORT', reportData)
      } else if (type === 'contentReports') {
        content.contentReports = reportData
        commit('SET_CONTENT_REPORT', reportData)
      } else if (type === 'anaReports') {
        content.anaReports = reportData
        commit('SET_ANA_REPORT', reportData)
      } else if (type === 'criterionReports') {
        content.criterionReports = reportData
        commit('SET_CRITERION_REPORT', reportData)
      } else if (type === 'knowledgeReports') {
        content.knowledgeReports = reportData
        commit('SET_KNOWLEDGE_REPORT', reportData)
      } else if (type === 'allReports') {
        content = { ...reportData }
        commit('SET_JUDGE_REPORT', content.judgeReports || [])
        commit('SET_LAW_REPORT', content.lawReports || [])
        commit('SET_CONTENT_REPORT', content.contentReports || [])
        commit('SET_ANA_REPORT', content.anaReports || [])
        commit('SET_KNOWLEDGE_REPORT', content.knowledgeReports || [])
        commit('SET_AGREEMENT_REPORT', content.agreementReports || [])
        commit('SET_CRITERION_REPORT', content.criterionReports || [])
        commit('SET_TEXT_REPORT', content.textReport || '')
        commit('SET_TEXT_REPORT', content.textReport || '')
      } else if (type === 'textReport') {
        content.textReport = reportData
        commit('SET_TEXT_REPORT', reportData)
      } else if (type === 'agreementReports') {
        content.agreementReports = reportData
        commit('SET_AGREEMENT_REPORT', reportData)
      }
    }
    console.log('修改最新一条检索报告文件 ======================>>', content)
    if (reportTimer) {
      clearTimeout(reportTimer)
      reportTimer = null
    }
    let time = 6 * 1000
    if (timeout === 0) {
      timeout = 0
    } else if (typeof timeout === 'number') {
      time = timeout
    }
    reportTimer = setTimeout(() => {
      console.log('调用接口修改')
      let url = ''
      let d = null
      const method = 'POST'
      if (state.ordinaryReport && state.ordinaryReport.id && !isAdd) {
        // 普通检索报告内不能修改其他信息
        const ordinaryReportContent = JSON.parse(state.ordinaryReport.content)
        content.userName = content.userName || ordinaryReportContent.userName || ''
        content.lawFirmName = content.lawFirmName || ordinaryReportContent.lawFirmName || ''
        content.dayTime = content.dayTime || ordinaryReportContent.dayTime || ''
        // content.avatarUrl = ordinaryReportContent.avatarUrl || ''
        content.hasFirstPage = content.hasFirstPage || ordinaryReportContent.hasFirstPage || false
        url = `${baseURL}/management/collection/updateFileInfos`
        d = {
          id: state.ordinaryReport.id,
          content: JSON.stringify(content),
          name: title || state.ordinaryReport.name,
          isDeletable: true,
          label: label || state.ordinaryReport.label,
        }
      } else {
        // 临时检索报告只存了报告数据没有其他信息
        url = `${baseURL}/management/user/${userId}/collection/searchReportCollection`
        d = {
          content: JSON.stringify(content),
          level: 1,
          name: title || '临时检索报告',
          isDeletable: true,
          size: 0,
          parentId: state.searchCollectionId,
          type: 'SEARCHREPORT',
        }
      }
      axios({
        url,
        method,
        data: { ...d },
      })
        .then((res) => {
          if (res.data && res.data.code === 200) {
            commit('SET_NEW_ORDINARY_REPORT', res.data.data)
            callback && callback()
          } else {
            callback && callback()
            console.log(res.data.message, '收藏失败')
          }
        })
        .catch((err) => {
          Promise.reject(err)
        })
    }, time)
  },
  // 删除最新一条报告的某类中的一条数据或者正文报告中的某一条（textArr,arr,anaArr）- 移动端不需要
  deleteOneReport({ state, commit }, { userId, payload }) {
    const contentReports = JSON.parse(JSON.stringify(state.contentReports))
    const anaReports = JSON.parse(JSON.stringify(state.anaReports))
    const content = {
      judgeReports: [...state.judgeReports],
      lawReports: [...state.lawReports],
      contentReports: [...contentReports],
      anaReports: [...anaReports],
      criterionReports: [...state.criterionReports],
      knowledgeReports: [...state.knowledgeReports],
      agreementReports: [...state.agreementReports],
      textReport: state.textReport,
    }
    if (payload.type === 'judgeReports') {
      content.judgeReports = content.judgeReports.filter((item) => {
        return item.doc_id !== payload.id
      })
    } else if (payload.type === 'lawReports') {
      content.lawReports = content.lawReports.filter((item) => {
        return item.law_id !== payload.id
      })
    } else if (payload.type === 'criterionReports') {
      content.criterionReports = content.criterionReports.filter((item) => {
        return item.nsId !== payload.id
      })
    } else if (payload.type === 'knowledgeReports') {
      content.knowledgeReports = content.knowledgeReports.filter((item) => {
        return item.url !== payload.id
      })
    } else if (payload.type === 'agreementReports') {
      content.agreementReports = content.agreementReports.filter((item) => {
        return item.id !== payload.id
      })
    } else if (payload.type === 'contentReports') {
      content.contentReports = content.contentReports.filter((item) => {
        return item.id !== payload.id
      })
    } else if (payload.type === 'contentArr') {
      const { id, innerIndex, outerIndex } = payload
      const index = content.contentReports.findIndex((item) => item.id === id)
      if (index !== -1) {
        const reportObj = content.contentReports[index]
        reportObj.arr[outerIndex].arr.splice(innerIndex, 1)
        if (reportObj.arr[outerIndex].arr.length === 0) {
          reportObj.arr.splice(outerIndex, 1)
        } else {
          content.contentReports[index] = reportObj
        }
        if (reportObj.arr.length === 0 && reportObj.textArr.length === 0) {
          content.contentReports.splice(index, 1)
        }
      }
    } else if (payload.type === 'contentTextArr') {
      const { id, index } = payload
      const ind = content.contentReports.findIndex((item) => item.id === id)
      if (ind !== -1) {
        const reportObj = content.contentReports[ind]
        // console.log(reportObj, 'reportObj reportObj reportObj')
        reportObj.textArr.splice(index, 1)
        content.contentReports[ind] = reportObj
        if (reportObj.arr.length === 0 && reportObj.textArr.length === 0) {
          content.contentReports.splice(ind, 1)
        }
      }
    } else if (payload.type === 'contentAnaArr') {
      const { id, index, anaType } = payload
      const ind = content.anaReports.findIndex((item) => item.id === id)
      const reportObj = content.anaReports[ind]
      if (anaType === 'law' && reportObj.type === 'law') {
        if (ind !== -1) {
          reportObj.anaArr.splice(index, 1)
          content.anaReports[ind] = reportObj
          if (reportObj.anaArr.length === 0) {
            content.anaReports.splice(ind, 1)
          }
        }
      } else if (anaType === reportObj.type) {
        if (ind !== -1) {
          content.anaReports.splice(ind, 1)
        }
      }
    } else if (payload.type === 'textReport') {
      content.textReport = ''
    }
    console.log(content, 'content=======>content')
    let url = ''
    let d = null
    if (state.ordinaryReport && state.ordinaryReport.id) {
      url = `${baseURL}/management/collection/updateFileInfos`
      d = {
        id: state.ordinaryReport.id,
        content: JSON.stringify(content),
        isDeletable: true,
      }
    }
    axios
      .post(url, d)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          const dataContent = JSON.parse(res.data.data.content) || null
          if (dataContent) {
            if (payload.type === 'judgeReports') {
              commit('SET_JUDGE_REPORT', dataContent.judgeReports)
            } else if (payload.type === 'lawReports') {
              commit('SET_LAW_REPORT', dataContent.lawReports)
            } else if (payload.type === 'contentReports' || payload.type === 'contentArr' || payload.type === 'contentTextArr') {
              commit('SET_CONTENT_REPORT', dataContent.contentReports)
            } else if (payload.type === 'contentAnaArr') {
              commit('SET_ANA_REPORT', dataContent.anaReports)
            } else if (payload.type === 'criterionReports') {
              commit('SET_CRITERION_REPORT', dataContent.criterionReports)
            } else if (payload.type === 'knowledgeReports') {
              commit('SET_KNOWLEDGE_REPORT', dataContent.knowledgeReports)
            } else if (payload.type === 'textReport') {
              commit('SET_TEXT_REPORT', dataContent.textReport)
            } else if (payload.type === 'agreementReports') {
              commit('SET_AGREEMENT_REPORT', dataContent.agreementReports)
            }
          } else {
            if (payload.type === 'judgeReports') {
              commit('SET_JUDGE_REPORT', [])
            } else if (payload.type === 'lawReports') {
              commit('SET_LAW_REPORT', [])
            } else if (payload.type === 'contentReports' || payload.type === 'contentArr' || payload.type === 'contentTextArr') {
              commit('SET_CONTENT_REPORT', [])
            } else if (payload.type === 'contentAnaArr') {
              commit('SET_ANA_REPORT', [])
            } else if (payload.type === 'criterionReports') {
              commit('SET_CRITERION_REPORT', [])
            } else if (payload.type === 'knowledgeReports') {
              commit('SET_KNOWLEDGE_REPORT', [])
            } else if (payload.type === 'textReport') {
              commit('SET_TEXT_REPORT', '')
            } else if (payload.type === 'agreementReports') {
              commit('SET_AGREEMENT_REPORT', '')
            }
          }
        } else {
          console.log(res.data.message, '收藏失败')
        }
      })
      .catch((err) => {
        Promise.reject(err)
      })
  },
  // 清空报告文件
  clearReport({ state, commit }, { userId, reportId }) {
    const content = {
      judgeReports: [],
      lawReports: [],
      contentReports: [],
      anaReports: [],
      criterionReports: [],
      knowledgeReports: [],
      agreementReports: [],
      textReport: '',
      userName: '',
    }
    let id = ''
    let name = ''
    let label = ''
    if (state.ordinaryReport) {
      id = state.ordinaryReport.id
      name = state.ordinaryReport.name
      label = state.ordinaryReport.label
    } else if (state.tempReport) {
      id = state.tempReport.id
      name = state.tempReport.name
      label = ''
    }
    const d = {
      content: JSON.stringify(content),
      name,
      label,
    }
    axios
      .put(`${baseURL}/management/user/${userId}/collection/${id}`, d)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          commit('SET_JUDGE_REPORT', [])
          commit('SET_LAW_REPORT', [])
          commit('SET_CONTENT_REPORT', [])
          commit('SET_ANA_REPORT', [])
          commit('SET_CRITERION_REPORT', [])
          commit('SET_TEXT_REPORT', '')
          commit('SET_CLEAR_CHECK', true)
        }
      })
      .catch((err) => {
        Promise.reject(err)
      })
  },
}
const getters = {
  reportCount: (state) => {
    let contentArrLen = 0
    let contentTextArrLen = 0
    if (state.contentReports && state.contentReports.length) {
      state.contentReports.forEach((item) => {
        if (item.arr && item.arr.length) {
          item.arr.forEach((v) => {
            if (v.arr) {
              contentArrLen += v.arr.length
            } else {
              contentArrLen += 1
            }
          })
        }
        if (item.textArr && item.textArr.length) {
          contentTextArrLen += item.textArr.length
        }
      })
    }
    let anaLen = 0
    if (state.anaReports && state.anaReports.length) {
      state.anaReports.forEach((item) => {
        if (item && item.anaArr && item.anaArr.length) {
          anaLen += item.anaArr.length
        }
      })
    }
    const judgeReportsLen = state.judgeReports ? state.judgeReports.length : 0
    const lawReportsLen = state.lawReports ? state.lawReports.length : 0
    const criterionReportsLen = state.criterionReports ? state.criterionReports.length : 0
    const knowledgeReportsLen = state.knowledgeReports ? state.knowledgeReports.length : 0
    const agreementReportsLen = state.agreementReports ? state.agreementReports.length : 0
    return judgeReportsLen + lawReportsLen + criterionReportsLen + knowledgeReportsLen + agreementReportsLen + contentArrLen + anaLen + contentTextArrLen
  },
  reportId: (state) => {
    return state.ordinaryReport ? state.ordinaryReport.id : ''
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
