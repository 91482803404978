<template>
  <div class="fb-invite">
    <div v-if="showHeadAndHistory" class="head">
      <div class="left-logo">
        <img src="../../../../public/img/text_logo.png" alt="" />
      </div>
      <div class="right-close" @click="emitClose">
        <i class="iconfont icon-clear"></i>
      </div>
    </div>
    <div class="info" :class="showHeadAndHistory ? '' : 'nohead'">
      <div class="info-title">
        <div>邀请好友助力</div>
        <div class="text-shandow">开通律呗仅需三步</div>
      </div>
      <div class="info-steps">
        <div class="step one">
          <div class="step-icon">
            <i class="iconfont icon-share-2"></i>
          </div>
          <span class="step-text">分享活动</span>
        </div>
        <div class="step-to-step">
          <i class="iconfont icon-more"></i>
          <i class="iconfont icon-gengduo"></i>
        </div>
        <div class="step two">
          <div class="step-icon">
            <i class="iconfont icon-fankui"></i>
          </div>
          <span class="step-text">接受邀请</span>
        </div>
        <div class="step-to-step">
          <i class="iconfont icon-more"></i>
          <i class="iconfont icon-gengduo"></i>
        </div>
        <div class="step three">
          <div class="step-icon">
            <i class="iconfont icon-shenfen"></i>
          </div>
          <span class="step-text">完成注册</span>
        </div>
      </div>
    </div>
    <div v-if="showHeadAndHistory" class="history">
      <div class="history-title">邀请记录</div>

      <ul class="list-body">
        <li class="item-head item">
          <div class="flex4">好友信息</div>
          <div class="flex5"></div>
          <div class="flex3">时间</div>
        </li>
        <li class="item-content item" v-for="(item, index) in inviteArr" :key="index">
          <div class="item-avatarUrl">
            <img :src="item.avatarUrl" alt="" />
          </div>
          <div class="item-nickName">{{ item.nickName }}</div>
          <div class="flex5">{{ item.statelabel }}</div>
          <div class="flex3">{{ item.time }}</div>
        </li>
        <li class="item-empty item">
          <div class="item-plus">
            <div>+</div>
          </div>
          <div class="item-uninvite">待邀请</div>
          <div class="flex5"></div>
          <div class="flex3"></div>
        </li>
      </ul>
      <div class="btn">
        <div class="invite-btn" @click="toInvite">
          立即邀请
          <div class="invite-tip">
            <div class="sanjiao">
              <i class="iconfont icon-right-flag"></i>
            </div>
            <span v-show="lastNum < 3">再邀请{{ lastNum }}名立得vip</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form" v-else>
      <div class="form-title">{{ sendUser.sendUserNickname }} 向您发送一个求助</div>
      <div class="form-content">
        <div class="content-title">
          完成注册 助力
          <span class="lawyer-name">{{ sendUser.sendUserNickname }}</span>
          得律呗vip
        </div>
        <div class="form-item">
          <input v-model="regForm.nickName" type="text" placeholder="请输入您的姓名" />
        </div>
        <div class="form-item">
          <input v-model="regForm.phone" type="text" placeholder="请输入您的手机号" />
        </div>
        <div class="code-item">
          <div class="form-input">
            <input v-model="regForm.refCode" type="text" placeholder="输入验证码" />
          </div>
          <span class="code" @click="sendCode">{{ canSend ? '获取验证码' : '还剩' + time + '秒' }}</span>
        </div>
      </div>
      <div class="btn">
        <div class="register-btn" @click="toRegister">立即注册</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fb-invite',
  props: {
    showHeadAndHistory: {
      type: Boolean,
      default: false,
    },
    sendUser: {
      type: Object,
      default: (_) => {},
    },
    promotionRecordId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inviteArr: [],
      lastNum: 2,
      regForm: {
        nickName: '',
        phone: '',
        refCode: '',
      },
      time: 0,
      canSend: true,
    }
  },
  methods: {
    // 获取助力列表
    async getInviteList() {
      try {
        const res = await this.$axios.post(`${this.$fissionBase}/fission/promotionAttentionRecord/list`)
        if (res.data && res.data.code === '200') {
          this.inviteArr = res.data.data.filter((item) => item.isAuthenticationLawyer === 1)
          this.inviteList.forEach((v) => {
            if (v.state === 0) {
              v.statelabel = '已注册'
            } else if (v.state === 1) {
              v.statelabel = '已购买'
            }
          })
          if (this.inviteArr.length < 2) {
            this.lastNum = 2 - this.inviteArr.length
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 获取验证码接口
    async sendCode() {
      if (this.canSend) {
        const phone = this.regForm.phone.trim()
        if (phone === '' || phone === null) {
          this.$message.error('请输入手机号')
          return
        }
        // if (!test('phone', phone)) {
        //   this.$message.error('手机号不正确')
        //   return
        // }
        const res = await this.$axios.get(`${this.$base}/farbun/user/smsResetVerifyCode/?phone=${phone}`)
        console.log(res, '调用获取验证码接口')
        if (res.data.code === 200) {
          this.$message.success('发送成功')
          var currentTime = 60
          this.time = currentTime
          this.canSend = false
          var interval = setInterval(() => {
            this.time = currentTime - 1
            currentTime--
            if (currentTime <= 0) {
              clearInterval(interval)
              this.canSend = true
              this.time = 0
            }
          }, 1000)
        }
      }
    },
    // 调用注册接口 - 移动端暂时不用注册
    async toRegister() {
      const { phone, nickName, refCode } = this.regForm
      if (!/^\d{4}$/.test(refCode)) {
        return true
      }
      try {
        const res = await this.$axios.post(`${this.$base}/farbun/user/register`, {
          phone,
          refCode,
          nickName,
          promotionRecordId: this.promotionRecordId,
        })
        if (res.data.code === 200) {
          if (res.data.data.userId) {
            this.$message.success('注册成功')
          }
        } else {
          this.$message(res.data.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async toInvite() {
      let promotionRecordId = ''
      let shareUrl = ''
      const name = '您的好友给您推荐了一款律师办公神器。点击查看:'
      const { data } = await this.$axios.post(`${this.$fissionBase}/fission/promotionRecord/generateInvitation`)
      if (data.code === '200' && data.data) {
        promotionRecordId = data.data.promotionRecordId
      }
      if (this.$base === 'https://api.farbun.com') {
        shareUrl = `https://tool.farbun.com/transform/sharevideo?id=${promotionRecordId}`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/sharevideo?id=${promotionRecordId}`
      }
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
      } else if (this.ua === this.$ua.i) {
        // ios环境
        // eslint-disable-next-line
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.copyShareUrl(shareUrl)
        console.log('网页端暂不支持分享', shareUrl)
      }
      this.$emit('close')
    },
    // 复制分享的URL
    copyShareUrl(text) {
      const textArea = document.createElement('textarea')
      textArea.value = '您的好友给您推荐了一款律师办公神器。点击查看:' + text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
      this.$notify({
        message: '分享地址已复制到剪贴板,请粘贴查看',
        type: 'success',
      })
    },
    emitClose() {
      this.$emit('close')
    },
  },
  mounted() {
    if (this.showHeadAndHistory) {
      this.getInviteList()
    }
  },
}
</script>

<style lang="stylus" scoped>
.fb-invite {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  background: linear-gradient(#409eff, #4169E1);
}
.head
  height 50px
  overflow hidden
  width 100%
  padding 0 10px
  box-sizing border-box
  display flex
  justify-content space-between
  color #fff
  line-height 50px
  .left-logo
    transform translateY(5px)
    img
      height 40px
  .right-close
    cursor pointer
    .iconfont
      font-size 30px
.info
  width 100%
  padding 0 10px
  box-sizing border-box
  color #fff
  .info-title
    padding 10px 0
    width 100%
    font-size 30px
    text-align center
    line-height 45px
    .text-shandow
      text-shadow 2px 2px 2px #4169E1
  .info-steps
    width 90%
    margin 0 auto
    display flex
    .step
      flex 3
      display flex
      flex-direction column
      justify-content center
      align-items center
      .step-icon
        width 50px
        height 50px
        text-align center
        line-height 50px
        border-radius 50%
        background-color #fff
        .iconfont
          font-size 24px
          color rgba(	65,105,225,.8)
      .step-text
        line-height 28px
    .step-to-step
      flex 2
      height 50px
      display flex
      justify-content center
      align-items center
      .iconfont
        font-size 18px
      .icon-more
        transform translateX(5px)
&.nohead
  padding-top 20px
  margin-bottom 10px
.history,.form
  width 95%
  margin 10px auto
  background-color #fff
  .history-title
    background-color #4169E1
    font-size 28px
    line-height 44px
    color #fff
    text-align center
  .form-title
    background-color #4169E1
    font-size 18px
    line-height 44px
    color #fff
    text-align center
  .list-body,.form-content
    background-color #fff
    padding 0 5px
    box-sizing border-box
    .item
      height 50px
      line-height 50px
      display flex
      margin-bottom 5px
      .flex2
        flex 2
        text-align center
      .flex3
        flex 3
        text-align center
      .flex4
        flex 4
        text-align center
      .flex5
        flex 5
        padding-left 30px
    .item-head
      height 40px
      line-height 40px
      border-bottom 1px dashed #ccc
    .item-content
      .item-avatarUrl
        flex 2
        text-align center
        img
          width 46px
          height 46px
          border-radius 50%
      .item-nickName
        flex 2
        padding-left 10px
    .item-empty
      margin-bottom 0
      .item-plus
        flex 2
        cursor pointer
        div
          width 42px
          height 42px
          text-align center
          line-height 46px
          margin 0 auto
          border-radius 50%
          font-size 32px
          background-color #fff
          border 1px solid #cccccc
      .item-uninvite
        flex 2
        padding-left 10px
    .content-title
      height 60px
      color #000
      line-height 60px
      text-align center
      font-size 20px
      font-weight bold
      .lawyer-name
        color orange
    .form-item
      width 100%
      height 50px
      line-height 50px
      display flex
      margin-bottom 10px
      border 1px solid #ccc
      border-radius 3px
      font-size 16px
      input
        flex 1
        border none
        outline none
        padding-left 10px
    .code-item
      display flex
      width 100%
      // height 50px
      margin-bottom 10px
      box-sizing border-box
      font-size 16px
      line-height 50px
      .form-input
        flex 1
        border 1px solid #ccc
        border-radius 3px
        height 100%
        input
          flex 1
          border none
          outline none
          padding-left 10px
      .code
        width 150px
        padding-left 10px
        color #666
        text-decoration underline
        cursor pointer
  .btn
    width 100%
    height 65px
    padding-top 10px
    cursor pointer
    .invite-btn,.register-btn
      width 80%
      height 46px
      line-height 46px
      margin 0 auto
      background-color orange
      text-align center
      font-size 20px
      color #8B4513
      border-radius 5px
      position relative
      .invite-tip
        position absolute
        right -10px
        top -20px
        font-size 14px
        line-height 24px
        color #fff
        background-color red
        padding 0px 12px
        border-radius 12px
        font-weight bold
        .sanjiao
          position absolute
          bottom -14px
          left 15px
          transform rotate(90deg)
          fone-weight normal
          .iconfont
            font-size 12px
            color red
</style>
