const state = () => ({
  orderId: '', // 团购订单pid
  inviteList: null, // 邀请记录
  promotionRecordId: '', // 邀请promotionRecordId
  groupInfo: {}, // 团购订单信息
  hasGetGroupinfo: false, // 是否获取过团购信息
  miniQRCode: '', // 小程序团购二维码
  exclusive: null, // 专属客服
  showInviteDialog: false, // 是否显示邀请弹窗面板
  isValid: false, // 是否有大数据页面访问权限
})

const mutations = {
  SET_VALID: (state, flag) => {
    state.isValid = flag
  },
  SET_INVITE_DIALOG_SHOW: (state, flag) => {
    state.showInviteDialog = flag
  },
  SET_INVITE_LIST: (state, list) => {
    if (list) {
      state.inviteList = list
    } else {
      state.inviteList = []
    }
  },
  SET_INVITE_CODE: (state, promotionRecordId) => {
    state.promotionRecordId = promotionRecordId
  },
  // 同步团购信息-几人团 已参团几人
  SET_GROUP_INFO: (state, groupInfo) => {
    if (groupInfo) {
      state.groupInfo = groupInfo
    } else {
      state.groupInfo = {}
    }
    state.hasGetGroupinfo = true
  },
  // 同步团购小程序码
  SET_MINI_QRCODE: (state, miniQRCode) => {
    state.miniQRCode = miniQRCode
  },
  SET_EXCLUSIVE: (state, exclusive) => {
    state.exclusive = exclusive
  },
}
const actions = {}
const getters = {
  pid: state => {
    return state.groupInfo && state.groupInfo.pid ? state.groupInfo.pid : null
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
