<template>
  <portal to="notify" v-if="visible">
    <div class="fb-notify" :style="styleComputed">
      <template v-if="isTextMsg">
        <span :class="[type]">{{ msg }}</span></template
      >
      <template v-else>
        <span>{{ msg.text }}</span>
        <span @click="onLinkClick" class="link">{{ msg.link }}</span>
      </template>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'fb-notify',
  props: {
    visible: Boolean,
    // 消息
    msg: [String, Object],
    // 时长 单位秒
    durantion: {
      type: Number,
      default: () => 2
    },
    background: {
      type: String,
      default: () => 'rgba(25, 137, 250, 0.9)'
    },
    type: {
      // success danger warning
      type: String,
      default: () => 'success'
    }
  },
  computed: {
    isTextMsg() {
      return typeof this.msg === 'string'
    },
    styleComputed() {
      const pattern = {
        success: 'rgba(25, 137, 250, 0.9)',
        danger: 'rgba(238, 10, 36, 0.9)',
        warning: 'rgba(255, 151, 106, 0.9)'
      }
      if (this.background) {
        return {
          background: this.background
        }
      } else if (this.type) {
        return {
          background: pattern[this.type]
        }
      } else {
        return {
          background: pattern.success
        }
      }
    }
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    }
  }
}
</script>

<style lang="stylus">
.fb-notify
  z-index 2999
  position fixed
  top 0
  left 0
  color #ffffff
  height 45px
  width 100%
  display flex
  align-items center
  justify-content center
  font-size 15px
  & .link
    margin-left 10px
    font-weight bold
    text-decoration underline
</style>
