const Home = () => import(/* webpackChunkName: "home-page" */ '@/pages/Home')
const CaseList = () =>
  import(/* webpackChunkName: "home-case" */ '@/pages/Home/pages/Case')

const PageNav = () =>
  import(/* webpackChunkName: "home-nav-page" */ '@/pages/Home/pages/Nav')
export default [
  {
    name: 'home',
    path: '/',
    component: Home,
    redirect: '/caselist',
    children: [
      {
        name: 'caselist',
        path: '/caselist',
        component: CaseList
      }
    ]
  },
  {
    path: '/nav',
    name: 'pagenav',
    component: PageNav
  }
]
