import axios from 'axios'
import { caseBase } from '@api-config'

const state = {
  userAgent: 'NORMAL_WEB',
  isLogin: false,
  // userInfo.id 是用户id
  userInfo: {},
  token: '',
  // 判例列表 dom
  currentObj: null,
  // 组织机构信息
  organization: null,
  organizationId: null,
  version: '',
  userinfo: {},
  userinfoAll: {},
  lawyerFlag: false,
}

const mutations = {
  setLoginVersion(state, version) {
    state.version = version
  },
  setUserCurrentObj(state, obj) {
    state.currentObj = obj
  },
  setUserAgent(state, agent) {
    state.userAgent = agent
  },
  setIsLogin(state, isLogin) {
    state.isLogin = isLogin
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setLawyerFlag(state, lawyerFlag) {
    state.lawyerFlag = lawyerFlag
    console.log('状态', state)
  },
  setToken(state, token) {
    state.token = token
    window.localStorage.setItem('token', token)
    window.sessionStorage.setItem('token', token)
  },
  resumeLogin(state, payload = { userInfo: {}, token: '', userinfoAll: {} }) {
    state.isLogin = true
    const { token, userInfo, userinfoAll } = payload
    state.userInfo = userInfo
    state.token = token
    state.userinfoAll = userinfoAll
    // window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
    // window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    // window.localStorage.setItem('token', token)
    // window.sessionStorage.setItem('token', token)
  },
  userLogout(state, logout) {
    state.isLogin = false
    state.userInfo = {}
    state.token = ''
    // 清除 localStorage 里的用户信息
    const ls = window.localStorage
    ls.removeItem('token')
    ls.removeItem('userInfo')
    // 清除 sessionStorage 里的用户信息
    const ss = window.sessionStorage
    ss.removeItem('token')
    ss.removeItem('userInfo')
  },
  setOrganizationId(state, organizationId) {
    state.organizationId = organizationId
  },
  setOrganizationData(state, data) {
    state.organization = data
  },
  setUserinfo(state, data) {
    state.userinfo = data
  },
}

const getters = {
  getUserInfo: (state) => {
    const ls = window.localStorage
    const ss = window.sessionStorage
    const lsUserToken = ls ? ls.getItem('userInfo') : null
    const ssUserToken = ss ? ss.getItem('userInfo') : null
    if (JSON.stringify(state.userInfo) !== '{}') {
      return state.userInfo
    } else if (ssUserToken && ssUserToken.userId !== '') {
      return JSON.parse(ssUserToken)
    } else if (lsUserToken && lsUserToken.userId !== '') {
      return JSON.parse(lsUserToken)
    } else {
      return {}
    }
  },
  getUserToken: (state) => {
    const ls = window.localStorage
    const ss = window.sessionStorage
    const lsUserToken = ls ? ls.getItem('token') : null
    const ssUserToken = ss ? ss.getItem('token') : null
    if (state.token !== '') {
      return state.token
    } else if (ssUserToken && ssUserToken !== '') {
      return ssUserToken
    } else if (lsUserToken && lsUserToken !== '') {
      return lsUserToken
    } else {
      return ''
    }
  },
  // 用户
  userId: (state) => {
    return state.userInfo && state.userInfo.id
  },
  // 是不是会员用户 -1 已过期，0非会员，1会员
  isVIP: (state) => {
    return !!(state.userInfo && state.userInfo.memberStatus === 1)
  },
  // 是否有大数据权限
  isValid: (state) => {
    return !!state.userInfo && state.userInfo.isValid
  },
  getLawState: () => {
    return state.lawyerFlag
  },
}
const actions = {
  async getOrganizationData({ state, commit }) {
    if (state.userInfo && state.userInfo.id) {
      const res = await axios.get(`${caseBase}/management/users/${state.userInfo.id}`)
      const { data } = res.data
      if (data && data.organization) {
        commit('setOrganizationData', data.organization)
        const { id } = data.organization
        if (id) {
          commit('setOrganizationId', id)
        }
      }
    }
  },
  async getOrganizationId({ state, commit }) {
    if (!state.userInfo && !state.userInfo.id) return
    const res = await axios.get(`${caseBase}/management/users/${state.userInfo.id}`)
    const { data } = res.data
    if (data && data.organization) {
      const { id } = data.organization
      if (id) {
        commit('setOrganizationId', id)
      }
    }
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
