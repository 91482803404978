var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bottom_tip" }, [
    _vm.visible && !_vm.showLimit
      ? _c(
          "div",
          {
            staticClass: "nonmember_limit",
            on: { click: _vm.openNonmemberDialog },
          },
          [_vm._v("查看全部内容")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }