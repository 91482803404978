/**
 * 用于#资讯搜索# 标签拖拽功能
 */

const state = {
  // 克隆类型元素在拖动中
  isCloneDragging: false,
  // 拖放类型元素在拖动中
  isDropDragging: false,
  dragSourceId: null,
  dragSourceData: null,
  popVisible: true,
  // 拖拽需要记录的数据
  dragMirrorPropStart: null,
  dragMirrorPropMove: null,
  dragMirrorPropEnd: null,
  // 动画提示是否可见
  animationHintVisible: false
}

const mutations = {
  SET_CLONE_DRAG: (state, dragging) => {
    state.isCloneDragging = dragging
  },
  SET_DROP_DRAG: (state, dragging) => {
    state.isDropDragging = dragging
    if (!dragging) {
      setTimeout(() => {
        state.dragSourceData = null
        state.dragSourceId = null
      }, 200)
    }
  },
  SET_DRAG_SOURCE_ID: (state, id) => {
    state.dragSourceId = id
  },
  SET_DRAG_SOURCE_DATA: (state, data) => {
    state.dragSourceData = data
  },
  SET_POP_VISIBLE: (state, visible) => {
    state.popVisible = visible
  },
  // 拖动开始
  SET_DRAG_MIRROR_PROP_START: (state, data) => {
    state.dragMirrorPropEnd = null
    state.dragMirrorPropStart = data
  },
  // 拖动移动
  SET_DRAG_MIRROR_PROP_MOVE: (state, data) => {
    state.dragMirrorPropMove = data
  },
  // 拖动结束
  SET_DRAG_MIRROR_PROP_END: (state, data) => {
    state.dragMirrorPropStart = null
    state.dragMirrorPropMove = null
    state.dragMirrorPropEnd = data
  },
  SET_ANIMATION_HINT_VISIBLE: (state, visible) => {
    state.animationHintVisible = visible
  },
  CLEAR_DRAG_STATE: state => {
    state.dragMirrorPropStart = null
    state.dragMirrorPropMove = null
    state.dragMirrorPropEnd = null
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
