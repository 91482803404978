var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      class: {
        "fb-header": true,
        "van-hairline--bottom": true,
        "fb-header-customer": this.customer,
        "is-fixed": _vm.fixed,
      },
    },
    [
      _c(
        "div",
        { staticClass: "fb-header-button is-left" },
        [_vm._t("left")],
        2
      ),
      !_vm.isdet
        ? _c("div", {
            staticClass: "fb-header-title",
            style: { flex: this.customer ? "8" : "1" },
            domProps: { textContent: _vm._s(_vm.title) },
            on: { click: _vm.clickTitle },
          })
        : _c("div", {
            staticClass: "fb-header-title",
            style: { flex: this.customer ? "8" : "1" },
            domProps: { innerHTML: _vm._s(_vm.title) },
            on: { click: _vm.clickTitle },
          }),
      _vm._t("copy"),
      _c(
        "div",
        { staticClass: "fb-header-button is-right" },
        [_vm._t("right")],
        2
      ),
      _vm._t("customer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }