var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fb-page", style: _vm.bgColor },
    [
      _c("div", { staticClass: "top" }, [_vm._t("top")], 2),
      _vm._t("header"),
      _c("div", { staticClass: "main-wrapper" }, [
        _c(
          "main",
          { staticClass: "content" },
          [
            _vm._t("default", function () {
              return [_vm._v(_vm._s(_vm.content))]
            }),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }