const Ana = () => import(/* webpackChunkName: "mapp-ana" */ '@/pages/Ana')
const Create = () =>
  import(
    /* webpackChunkName: "mapp-ana-create" */ '@/pages/Ana/pages/analysis/components/create.vue'
  )
// 分析报告
const anaReport = () =>
  import(
    /* webpackChunkName: "mapp-ana-report" */ '@/pages/Ana/pages/analysis/pages/anaReport'
  )

const ChangeCase = () =>
  import(
    /* webpackChunkName: "mapp-ana-changeCase" */ '@/pages/Ana/pages/analysis/components/changeCase.vue'
  )
const ScreenCondition = () =>
  import(
    /* webpackChunkName: "mapp-ana-ScreenCondition" */ '@/pages/Ana/pages/analysis/components/screenCondition.vue'
  )
const anaTwoKeyWord = () =>
  import(
    /* webpackChunkName: "mapp-ana-keyword" */ '@/pages/Ana/pages/analysis/components/keyword.vue'
  )
const spendDialog = () =>
  import(
    /* webpackChunkName: "mapp-ana-keyword" */ '@/pages/Ana/pages/analysis/components/spendDialog.vue'
  )

export default [
  {
    path: '/ana',
    name: 'Ana',
    component: Ana,
    meta: {
      fn: 'cancel',
    },
  },
  {
    path: '/ana/create',
    name: 'createAna',
    component: Create,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/ana/changeCase',
    name: 'changeCase',
    component: ChangeCase,
  },
  {
    path: '/ana/ScreenCondition',
    name: 'screenCondition',
    component: ScreenCondition,
  },
  {
    path: '/ana/anatwokeyword',
    name: 'anatwokeyword',
    component: anaTwoKeyWord,
  },
  {
    path: '/ana/report',
    name: 'anaReport',
    component: anaReport,
  },
  {
    path: '/spendDialog',
    name: 'spendDialog',
    component: spendDialog,
  },
]
