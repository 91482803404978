// 总史商联
const Business = () =>
  import(/* webpackChunkName: "home-casedetail-Information-business" */ '@/pages/CaseDetail/pages/Information/business')
const Contact = () =>
  import(/* webpackChunkName: "home-casedetail-Information-contact" */ '@/pages/CaseDetail/pages/Information/contact')
const Historycase = () =>
  import(/* webpackChunkName: "home-casedetail-Informationhistorycase" */ '@/pages/CaseDetail/pages/Information/historycase')
const HistorycaseList = () =>
  import(/* webpackChunkName: "home-casedetail-Informationhistorycase-list" */ '@/pages/CaseDetail/pages/Information/historycase/list.vue')
const HistorycaseVerdict = () =>
  import(/* webpackChunkName: "home-casedetail-Informationhistorycase-verdict" */ '@/pages/CaseDetail/pages/Information/historycase/verdict.vue')
const HistorycaseContent = () =>
  import(/* webpackChunkName: "home-casedetail-Information-historycase-content" */ '@/pages/CaseDetail/pages/Information/historycase/content.vue')
const Summary = () =>
  import(/* webpackChunkName: "home-casedetail-Information-summary" */ '@/pages/CaseDetail/pages/Information/summary')
const SummaryLigitant = () =>
  import(/* webpackChunkName: "home-casedetail-Information-summary-ligitant" */ '@/pages/CaseDetail/pages/Information/summary/ligitant.vue')
const SummaryList = () =>
  import(/* webpackChunkName: "home-casedetail-Information-summary-list" */ '@/pages/CaseDetail/pages/Information/summary/list.vue')
const SummaryDetail = () =>
  import(/* webpackChunkName: "home-casedetail-Information-summary-detail" */ '@/pages/CaseDetail/pages/Information/summary/detail.vue')
const SummaryOrgan = () =>
  import(/* webpackChunkName: "home-casedetail-Information-summary-organ" */ '@/pages/CaseDetail/pages/Information/summary/organ.vue')
const ZhiShiShu = () =>
  import(/* webpackChunkName: "home-casedetail-Information-zhishishu" */ '@/pages/CaseDetail/pages/Information/zhishishu/index.vue')
const Linshiziliao = () =>
  import(/* webpackChunkName: "home-casedetail-Information-linshiziliao" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/linshiziliao')
const Anjiandangan = () =>
  import(/* webpackChunkName: "home-casedetail-Information-anjiandangan" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/anjiandangan')
const Wenshumoban = () =>
  import(/* webpackChunkName: "home-casedetail-Information-wenshumoban" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/wenshumoban')
const Wodezhishi = () =>
  import(/* webpackChunkName: "home-casedetail-Information-wodezhishi" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/wodezhishi')
const Xitongzhishi = () =>
  import(/* webpackChunkName: "home-casedetail-Information-xitongzhishi" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/xitongzhishi')
const Kehuliebiao = () =>
  import(/* webpackChunkName: "home-casedetail-Information-Kehuliebiao" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/anjiandangan/views/kehuliebiao.vue')
const KehuliebiaoEdit = () =>
  import(/* webpackChunkName: "home-casedetail-Information-Kehuliebiaoedit" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/anjiandangan/views/kehuanjianedit.vue')
const Anjianjiebiao = () =>
  import(/* webpackChunkName: "home-casedetail-Information-Anjianjiebiao" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/anjiandangan/views/anjianliebiao.vue')
const PreView = () =>
  import(/* webpackChunkName: "home-casedetail-Information-PreView" */ '@/pages/CaseDetail/pages/Information/zhishishu/views/anjiandangan/views/preview.vue')

export default [
  {
    name: 'casedetail-xxzl',
    path: '/casedetail/:caseId/xxzl',
    component: Summary,
    redirect: '/casedetail/:caseId/xxzl/list',
    children: [
      {
        name: 'casedetail-xxzl-list',
        path: 'list',
        component: SummaryList
      },
      {
        name: 'casedetail-xxzl-ligitant',
        path: 'ligitant',
        component: SummaryLigitant
      },
      {
        name: 'casedetail-xxzl-detail',
        path: 'detail',
        component: SummaryDetail
      },
      {
        name: 'casedetail-xxzl-organ',
        path: 'organ',
        component: SummaryOrgan
      }
    ]
  },
  {
    name: 'casedetail-lsal',
    path: '/casedetail/:caseId/lsal',
    redirect: '/casedetail/:caseId/lsal/list',
    component: Historycase,
    children: [
      {
        name: 'casedetail-lsal-list',
        path: 'list',
        component: HistorycaseList
      },
      {
        name: 'casedetail-lsal-verdict',
        path: 'verdict',
        component: HistorycaseVerdict
      },
      {
        name: 'casedetail-lsal-content',
        path: 'content',
        component: HistorycaseContent
      }
    ]
  },
  {
    name: 'casedetail-gsxx',
    path: '/casedetail/:caseId/gsxx',
    component: Business
  },
  {
    name: 'casedetail-lxfs',
    path: '/casedetail/:caseId/lxfs',
    component: Contact
  },
  {
    name: 'casedetail-zss',
    path: '/casedetail/zss/:caseId',
    component: ZhiShiShu,
    children: [
      {
        name: 'casedetail-linshiziliao',
        path: '/casedetail/zss/linshiziliao/:caseId',
        component: Linshiziliao
      },
      {
        name: 'casedetail-anjiandangan',
        path: '/casedetail/zss/anjiandangan/:caseId',
        component: Anjiandangan
      },
      {
        name: 'casedetail-wenshumoban',
        path: '/casedetail/zss/wenshumoban/:caseId',
        component: Wenshumoban
      },
      {
        name: 'casedetail-wodezhishi',
        path: '/casedetail/zss/wodezhishi/:caseId',
        component: Wodezhishi
      },
      {
        name: 'casedetail-xitongzhishi',
        path: '/casedetail/zss/xitongzhishi/:caseId',
        component: Xitongzhishi
      }
    ]
  },
  {
    name: 'casedetail-kuhuliebiao',
    path: '/casedetail/zss/kuhuliebiao/:litigantId',
    component: Kehuliebiao
  },
  {
    name: 'casedetail-kuhuliebiao-edit',
    path: '/casedetail/zss/kuhuliebiaoEdit/:id',
    component: KehuliebiaoEdit
  },
  {
    name: 'casedetail-anjianliebiao',
    path: '/casedetail/zss/anjianliebiao/:id',
    component: Anjianjiebiao
  },
  {
    name: 'casedetail-zss-preview',
    path: '/casedetail/zss/preview/:url',
    component: PreView
  }
]
