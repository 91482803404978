const LawyerAuth = () =>
  import(/* webpackChunkName: "lawyer-auth-page" */ '@/pages/LawyerAuth')
const ocrLawyerAuth = () =>
  import(/* webpackChunkName: "ocr-lawyer-auth-page" */ '@/pages/LawyerAuth/ocrLawyerAuth')
const institutions = () =>
  import(/* webpackChunkName: "lawyer-auth-page-institutions" */ '@/pages/institutions')
const lawyerCost = () =>
  import(/* webpackChunkName: "lawyer-auth-page-institutions" */ '@/pages/LawyerCost')
export default [
  {
    name: 'lawyerAuth',
    path: '/lawyerAuth',
    component: LawyerAuth,
  },
  {
    name: 'ocrLawyerAuth',
    path: '/ocrLawyerAuth',
    component: ocrLawyerAuth,
  },
  {
    name: 'institutions',
    path: '/institutions',
    component: institutions,
  },
  {
    name: 'lawyerCost',
    path: '/LawyerCost',
    component: lawyerCost,
  },
]
