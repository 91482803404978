<template>
  <div class="fbLoading"
       v-show="loading">
    <van-loading />
    <div class="loading-text"
         v-if="text">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: 'fb-loading',
  props: {
    loading: Boolean,
    text: String
  }
}
</script>
<style lang="stylus" scoped>
.fbLoading
  display flex
  justify-content center
  align-items center
  flex-direction column
  margin 10px 0
  .loading-text
    font-size 12px
    color #999
    margin-top 5px
</style>
