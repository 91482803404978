// 工具
const Tools = () =>
  import(/* webpackChunkName: "tools" */ '@/pages/tools/index.vue')

// 反馈
const feedback = () =>
  import(/* webpackChunkName: "feedback" */ '@/pages/tools/pages/feedback/index.vue')

// 身份证查询
const idCard = () =>
  import(/* webpackChunkName: "idCard" */ '@/pages/tools/pages/idCard/index.vue')

// 诉讼费
const litigation = () =>
  import(/* webpackChunkName: "litigation" */ '@/pages/tools/pages/litigation/index.vue')

// 延迟利息
const deferredInterest = () =>
  import(/* webpackChunkName: "deferredInterest" */ '@/pages/tools/pages/deferredInterest/index.vue')

// 利息计算
const interest = () =>
  import(/* webpackChunkName: "interest" */ '@/pages/tools/pages/interest/index.vue')

// 天数计算
const computeDays = () =>
  import(/* webpackChunkName: "computeDays" */ '@/pages/tools/pages/computeDays/index.vue')

// 死亡赔偿
const deathCompensation = () =>
  import(/* webpackChunkName: "deathCompensation" */ '@/pages/tools/pages/deathCompensation/index.vue')

// 伤残赔偿
const disabilityCompensation = () =>
  import(/* webpackChunkName: "disabilityCompensation" */ '@/pages/tools/pages/disabilityCompensation/index.vue')

// 伤残赔偿
const wechatH5 = () =>
  import(/* webpackChunkName: "wechatH5" */ '@/pages/tools/pages/wechat/H5/index.vue')

// 律师费
const lawyerFee = () =>
  import(/* webpackChunkName: "lawyerFee" */ '@/pages/tools/pages/lawyerFee/index.vue')
export default [
  {
    path: '/transform/tools/',
    name: 'tools',
    component: Tools
  },
  {
    path: '/transform/tools/feedback',
    name: 'feedback',
    component: feedback
  },
  {
    path: '/transform/tools/idCard',
    name: 'idCard',
    component: idCard
  },
  {
    path: '/transform/tools/litigation',
    name: 'litigation',
    component: litigation
  },
  {
    path: '/transform/tools/interest',
    name: 'interest',
    component: interest
  },
  {
    path: '/transform/tools/deferredInterest',
    name: 'deferredInterest',
    component: deferredInterest
  },
  {
    path: '/transform/tools/computeDays',
    name: 'computeDays',
    component: computeDays
  },
  {
    path: '/transform/tools/deathCompensation',
    name: 'deathCompensation',
    component: deathCompensation
  },
  {
    path: '/transform/tools/disabilityCompensation',
    name: 'disabilityCompensation',
    component: disabilityCompensation
  },
  {
    path: '/transform/tools/lawyerFee',
    name: 'lawyerFee',
    component: lawyerFee
  },
  {
    path: '/wechat/H5',
    name: 'wechatH5',
    component: wechatH5
  }
]
