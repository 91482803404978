const CreateCase = () =>
  import(/* webpackChunkName: "mapp-createcase-page" */ '@/pages/CreateCase')
// const CreateCase = () =>
//   import(/* webpackChunkName: "mapp-ana" */ '@/pages/Ana')
export default [
  {
    name: 'createcase',
    path: '/createcase',
    component: CreateCase
  }
]
