const CaseDetail = () =>
  import(/* webpackChunkName: "home-casedetail" */ '@/pages/CaseDetail')
const Timeline = () =>
  import(/* webpackChunkName: "home-casedetail-Timeline" */ '@/pages/CaseDetail/pages/Timeline')
const Document = () =>
  import(/* webpackChunkName: "home-casedetail-Document" */ '@/pages/CaseDetail/pages/Document')
const DocumentContent = () =>
  import(/* webpackChunkName: "home-casedetail-Document-content" */ '@/pages/CaseDetail/pages/Document/content.vue')
const Evidence = () =>
  import(/* webpackChunkName: "home-casedetail-Evidence" */ '@/pages/CaseDetail/pages/Evidence')
const Msg = () =>
  import(/* webpackChunkName: "home-casedetail-Msg" */ '@/pages/CaseDetail/pages/Msg')
export default [
  {
    name: 'casedetail',
    path: '/casedetail/:caseId',
    component: CaseDetail,
    redirect: '/casedetail/:caseId/timeline',
    children: [
      {
        name: 'casedetail-timeline',
        path: '/casedetail/:caseId/timeline',
        component: Timeline,
        props: true
      },
      {
        name: 'casedetail-evidence',
        path: '/casedetail/:caseId/evidence',
        component: Evidence
      },
      {
        name: 'casedetail-document',
        path: '/casedetail/:caseId/document',
        component: Document
      },
      {
        name: 'casedetail-document-content',
        path: '/casedetail/:caseId/document/content',
        component: DocumentContent
      },
      {
        name: 'casedetail-msg',
        path: '/casedetail/:caseId/msg',
        component: Msg
      }
    ]
  }
]
