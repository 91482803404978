const state = {
  today: 1527343534569, // 服务器上时间戳
  caseFilter: '', // 筛选案件
  limit: 10, // 返回条数
  pageNum: 1, // 页码
  TLRange: {}, // 时间轴的事件范围
  TLWidth: 0, // 时间轴的宽度（单位px）
  TLHeight: 0, // 时间轴的高度（单位px）
  TLMode: 'b', // 时间轴的视图模式
  backToTodayTranslateXRem: 1.8, // 回到今天距离时间轴开始位置的x方向偏移值 单位为rem 使用时根据dpr计算出像素
  TLTouching: false, // 标记时间轴是否在拖动中
  TLCanMove: true, // 时间轴是否可以拖动
  canView: true // 是否可以查看案件
}

const mutations = {
  setToday (state, today) {
    state.today = today
  },
  setTLRange (state, range) {
    state.TLRange = range
  },
  setTLWidth (state, width) {
    state.TLWidth = width
  },
  setTLHeight (state, height) {
    state.TLHeight = height
  },
  setTLMode (state, mode) {
    state.TLMode = mode
  },
  setTLTouching (state, isTouching) {
    state.TLTouching = isTouching
  },
  setTLCanMove (state, canMove) {
    state.TLCanMove = canMove
  },
  setCaseFilter (state, filters) {
    state.caseFilter = filters
  },
  setPageNum (state, pageNum) {
    state.pageNum = pageNum
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setCanView (state, can) {
    state.canView = can
  }
}

const getters = {
  getTLRangeStamp: state => {
    const start = new Date(state.TLRange.start).getTime()
    const end = new Date(state.TLRange.end).getTime()
    return { start, end }
  },
  getTLRangeCrossNumber: state => {
    const range = new Date(state.TLRange.end) - new Date(state.TLRange.start)
    return range
  },
  getBackToTodayTranslateX: state => {
    const fontSize = document.documentElement.style.fontSize.replace(/px/g, '')
    return state.backToTodayTranslateXRem * fontSize
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
