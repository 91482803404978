const dingjuan = () => import(/* webpackChunkName: "mapp-skyDrive" */ '@/pages/dingjuan/index')
const imgText = () => import(/* webpackChunkName: "mapp-skyDrive" */ '@/pages/dingjuan/components/imgText')
const dangshiren = () => import(/* webpackChunkName: "mapp-skyDrive" */ '@/pages/dingjuan/components/dangshiren')
const fileDownlaod = () => import(/* webpackChunkName: "mapp-skyDrive" */ '@/pages/dingjuan/components/fileDownlaod')
export default [
  {
    path: '/dingjuan',
    name: 'dingjuan',
    component: dingjuan,
  },
  {
    path: '/dingjuan/imgText',
    name: 'imgText',
    component: imgText,
  },
  {
    path: '/dingjuan/dangshiren',
    name: 'dangshiren',
    component: dangshiren,
  },
  {
    path: '/dingjuan/fileDownlaod',
    name: 'fileDownlaod',
    component: fileDownlaod,
  },
]
