const searchReport = () => import('@/pages/transform/searchReport')
const searchReportId = () => import('@/pages/transform/searchReport/reportId')
export default [
  {
    path: '/transform/searchReport',
    name: 'searchReport',
    component: searchReport
  },
  {
    path: '/transform/searchReport/:reportId',
    name: 'searchReportId',
    component: searchReportId
  }
]
