import { isFilledArray, typeOf } from '@/utils/assist'
import Vue from 'vue'

export const EventBus = new Vue()

/**
 * eventBus的推荐用法
 * !事件名和处理函数需要成对使用 以便于在组件销毁时解除事件监听
 * @param {Object | Array} events { name, handler } 或者是数组,
 * @param {vue.comp} ctx vue组件的上下文
 */
export const useEventBus = (events, ctx) => {
  let evts
  const t = typeOf(events)
  if (t === 'array') {
    evts = events
  } else if (t === 'object' && t.name && t.handler) {
    evts = [events]
  }
  if (!isFilledArray(evts)) return

  const ON = ev => {
    EventBus.$on(ev.name, ev.handler)
  }
  const OFF = ev => {
    EventBus.$off(ev.name, ev.handler)
  }

  // 监听
  evts.forEach(ON)
  // 解除监听
  ctx.$once('hook:destroyed', () => {
    evts.forEach(OFF)
  })
}
