import { pydataBase } from '~api-config'
const state = {
  active: 0,
  appeal: {
    data: [],
    conditions: [],
    total_nums: 0,
    offset: 1,
    loading: false,
    finished: false,
    Notify: true
  },
  knowledge: {},
  law: {
    data: [],
    condition_data: [],
    total_nums: 0,
    offset: 1,
    loading: false,
    finished: false,
    Notify: true
  },
  document: [],
  keyWords: [],
  showInput: false,
  lawDetail: {},
  loading: { appeal: true, knowledge: true, law: true, document: true },
  matching: { data: {} },
  blooen: false,
  searchHistory: { data: [] },
  activeButton: 0,
  showmenu: false, // 侧边栏
  cache_data: null,
  searchVideoFlag: false // 大数据页面帮助视频是否播放
}
const getters = {
  get_cache_data(state) {
    return state.cache_data
  },
  appealTips(state) {
    const array = []
    state.appeal.conditions.forEach(item => {
      array.push(...item.data)
    })
    const tips = []
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      if (item.isChecked) {
        tips.push(item)
      }
      if (item.child) {
        array.push(item.child)
      }
    }
    return tips
  },
  lawTips(state) {
    const array = []
    state.law.condition_data.forEach(item => {
      array.push(...item.data)
    })
    const tips = []
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      if (item.isChecked) {
        tips.push(item)
      }
      if (item.child) {
        array.push(item.child)
      }
    }
    return tips
  }
}
const mutations = {
  SET_SEARCHVIDEOFLAG: (state, flag) => {
    state.searchVideoFlag = flag
  },
  setData(state, val) {
    state.cache_data = val
  },
  setShowMenu(state, bool) {
    state.showmenu = bool
  },
  resetLoading(state) {
    state.loading = {
      appeal: true,
      knowledge: true,
      law: true,
      document: true
    }
  },
  changeKeowLedge(state, knew) {
    state.knowledge = knew.data
  },
  SearchAppeal(state, appeal) {
    state.appeal.conditions = appeal.conditions
    state.appeal.data = appeal.data
    state.appeal.total_nums = appeal.total_nums
    state.loading.appeal = false
  },
  SearchLaw(state, law) {
    state.law.condition_data = law.condition_data
    state.law.data = law.data
    state.law.total_nums = law.total_nums
    state.loading.law = false
  },
  getKnowLedge(state, know) {
    state.knowledge = know.data
  },
  getKeyWords(state, keyWords) {
    state.keyWords.push(keyWords)
  },
  addShowInput(state, show) {
    state.showInput = show.showInput
    // state.activeButton = show.activeButton - 1
  },
  keyWordsDelete(state, index) {
    state.keyWords.splice(index, 1)
  },
  addActive(state, tabs) {
    state.active = tabs.active
  },
  addMaching(state, data) {
    state.matching = data
  },
  addSearchHistory(state, data) {
    state.searchHistory = data.data
    state.blooen = data.blooen
  },
  addActives(state, active) {
    state.active = active
    if (active === 0) {
      state.activeButton = 0
    } else {
      state.activeButton = active - 1
    }
  },
  addActiveButton(state, button) {
    state.activeButton = button.activeButton - 1
  },
  // 下拉加载
  // 判例
  loadingAppeal(state, data) {
    if (data.data.data.length > 0) {
      data.data.data.forEach((value, index) => {
        state.appeal.data.push(value)
      })
      // data.data.conditions.forEach((value, index) => {
      //   state.appeal.condition_data.push(value)
      // })
      state.appeal.conditions = data.data.conditions
      state.appeal.total_nums = data.data.total_nums
      state.appeal.offset += 1
      setTimeout(() => {
        state.appeal.loading = false
      }, 0)
    } else {
      state.appeal.loading = false
      state.appeal.finished = true
    }
  },
  // 法律
  loadingLaw(state, data) {
    if (data.data.data.length > 0) {
      data.data.data.forEach((value, index) => {
        state.law.data.push(value)
      })
      data.data.condition_data.forEach((value, index) => {
        state.law.condition_data.push(value)
      })
      state.law.total_nums = data.data.total_nums
      state.law.offset += 1
      setTimeout(() => {
        state.law.loading = false
      }, 0)
    } else {
      state.law.loading = false
      state.law.finished = true
    }
  },
  NotifyLaw(state, data) {
    state.law.Notify = data.Notify
  },
  NotifyAppeal(state, data) {
    state.appeal.Notify = data.Notify
  },
  PushKeyWods(state, data) {
    state.keyWords.push(data.key)
  }
  // addKeyWords (state, data) {
  //   state.keyWords.push(data.keyWords)
  // }
}
const actions = {
  async addKeyWords({ commit, state, getters }, products) {
    if (products.http) {
      commit('resetLoading')
      const sideParams = () => {
        if (products.type === 'appeal') {
          let param = ''
          getters.appealTips.forEach(item => {
            param += '&condition=' + item.key
          })
          return param
        } else if (products.type === 'law') {
          let param = ''
          getters.lawTips.forEach(item => {
            param += '&condition=' + item.key
          })
          return param
        } else {
          return ''
        }
      }
      // console.log(sideParams())
      const hasUserId = () =>
        this.state.user.userInfo.id
          ? `&user_id=${this.state.user.userInfo.id}`
          : ''
      if (
        products.keyWords ||
        (state.keyWords.length > 0 && state.active < 3)
      ) {
        var condition = ''
        state.keyWords.forEach((item, index) => {
          condition += '&condition=' + item
        })
        const arr = [
          `${pydataBase}/api/nvi/judgementSearch`,
          `${pydataBase}/api/nvi/lawSearch`
        ]
        const _url = arr[state.active - 1]
        const paramsA = {
          offset: 1,
          sort: 0,
          query_condition: `${condition}${hasUserId()}`,
          loc_condition: [],
          filter_condition: []
        }
        try {
          const resLaw = await products.http.post(_url, paramsA)
          const SearchLaw = resLaw.data.data
          console.log(SearchLaw, 789)
          switch (state.active - 1) {
            case 0:
              commit('SearchAppeal', SearchLaw)
              break
            case 1:
              commit('SearchLaw', SearchLaw)
              break
          }
        } catch (err) {
          commit('NotifyLaw', { Notify: false })
        }
      } else {
        condition = ''
        state.keyWords.forEach((item, index) => {
          condition += '&condition=' + item
        })
        const faBunLaw = `${pydataBase}/api/nvi/recommend?sort_type=1${condition}&offset=1${hasUserId()}${sideParams()}`
        try {
          const resLaw = await products.http.post(faBunLaw)
          resLaw.data.data.forEach(item => {
            switch (item.label) {
              case '判例':
                // products.http
                //   .post(
                //     `${pydataBase}/api/nvi/search_precedent?sort_type=0${condition}&offset=${
                //       state.appeal.offset
                //     }${hasUserId()}`
                //   )
                products.http
                  .post(`${pydataBase}/api/nvi/judgementSearch`, {
                    sort: 0,
                    offset: 1,
                    // query_condition: `${condition}${hasUserId()}`,
                    query_condition: [],
                    loc_condition: [],
                    filter_condition: []
                  })
                  .then(data => {
                    item.conditions = data.data.data.conditions
                    item.total_nums = data.data.data.total_nums
                    console.log(item, 999)
                    commit('SearchAppeal', item)
                  })
                break
              case '法律':
                // .post(
                //   `${pydataBase}/api/nvi/search_law?sort_type=1&condition=&offset=${
                //     state.law.offset
                //   }${hasUserId()}`
                // )
                products.http
                  .post(`${pydataBase}/api/nvi/lawSearch`, {
                    sort: 0,
                    offset: 1,
                    query_condition: [],
                    loc_condition: [],
                    filter_condition: []
                  })
                  .then(data => {
                    item.condition_data = data.data.data.condition_data
                    item.total_nums = data.data.data.total_nums
                    console.log(item, 888)

                    commit('SearchLaw', item)
                  })
                break
              case '知识':
                commit('getKnowLedge', item)
                break
            }
          })
        } catch (err) {
          commit('NotifyLaw', { Notify: false })
        }
        // const farBun = `${caseBase} api/nvi/recommend?offset=1&user_id=${
        //   this.$store.state.user.userInfo.id
        // }`
        // const resLaw = await products.http.post(farBun)
      }
    } else {
      // input失去焦点未点击搜索
      commit('getKeyWords', products.keyWords)
    }
  },
  // 判例页下拉加载
  async httpAppeal({ commit, state }, products) {
    // const hasUserId = () =>
    //   this.state.user.userInfo.id? `&user_id=${this.state.user.userInfo.id}`: ''
    // if (state.keyWords === []) {
    //   const faBunAppeal = `${pydataBase}/api/nvi/recommend?sort_type=0&condition=&offset=${
    //     state.appeal.offset
    //   }${hasUserId()}`
    //   try {
    //     const resAppeal = await products.http.post(faBunAppeal)
    //     commit('loadingAppeal', resAppeal.data)
    //   } catch (err) {
    //     commit('NotifyAppeal', { Notify: true })
    //     products.notify('加载失败')
    //   }
    // } else {
    // var condition = ''
    // state.keyWords.forEach((item, index) => {
    //   condition += '&condition=' + item
    // })
    // const faBunAppeal = `${pydataBase}/api/nvi/search_precedent`?sort_type=0${condition}&offset=${
    //   state.appeal.offset
    // }${hasUserId()}`
    const faBunAppeal = `${pydataBase}/api/nvi/judgementSearch`
    let flag = false
    try {
      const resAppeal = await products.http.post(faBunAppeal, {
        sort: 0,
        offset: 1,
        query_condition: [],
        loc_condition: [],
        filter_condition: []
      })
      flag = true
      commit('loadingAppeal', resAppeal.data)
    } catch (err) {
      if (!flag) {
        commit('NotifyAppeal', { Notify: true })
        products.notify('加载失败')
        if (this.state.search.active === 1) {
          products.scrollContainer.scrollTop = 0
        }
        setTimeout(() => {
          this.state.search.appeal.loading = false
        }, 100)
      }
    }
    // }
  },
  // 法律页下拉加载
  async httpLaw({ commit, state }, products) {
    // const hasUserId = () =>
    //   this.state.user.userInfo.id? `&user_id=${this.state.user.userInfo.id}`: ''
    if (state.keyWords.length < 1) {
      // `${pydataBase}/api/nvi/search_law?sort_type=1&condition=&offset=${
      //   state.law.offset}${hasUserId()}`
      const faBunLaw = `${pydataBase}/api/nvi/lawSearch`
      let flag = false
      try {
        const resLaw = await products.http.post(faBunLaw, {
          sort: 0,
          offset: 1,
          query_condition: [],
          loc_condition: [],
          filter_condition: []
        })
        flag = true
        commit('loadingLaw', resLaw.data)
      } catch (err) {
        if (flag) {
          commit('NotifyLaw', { Notify: false })
          products.notify('加载失败')
          if (this.state.search.active === 2) {
            products.scrollContainer.scrollTop = 0
          }
          setTimeout(() => {
            this.state.search.law.loading = false
          }, 100)
        }
      }
    } else {
      // var condition = ''
      // state.keyWords.forEach((item, index) => {
      //   condition += '&condition=' + item
      // })
      let flag = false
      try {
        const faBunLaw = `${pydataBase}/api/nvi/lawSearch`
        const resLaw = await products.http.post(faBunLaw, {
          sort: 0,
          offset: 1,
          query_condition: [],
          loc_condition: [],
          filter_condition: []
        })
        flag = true
        commit('loadingLaw', resLaw.data)
      } catch (err) {
        if (flag) {
          commit('NotifyLaw', { Notify: false })
          products.notify('加载失败')
          if (this.state.search.active === 2) {
            products.scrollContainer.scrollTop = 0
          }
          setTimeout(() => {
            this.state.search.law.loading = false
          }, 100)
        }
      }
    }
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
