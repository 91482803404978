import { useEventBus } from '@/common/eventBus'

// web端定义给原生端调用的bridge方法
export const BRIDGE_FUNCTIONS_FOR_NATIVE = {
  createCaseOcr: {
    name: 'ocrCaseResultWithFiles',
    param: 'JSONstring',
    desc: '原生端返回拍照处理的数据',
  },
  uploadFiles: {
    name: 'uploadFiles',
    param: 'JSON string',
    desc: '原生端返回上传数据',
  },
  handleMemberSuccess: {
    name: 'vipRechargeSuccess',
    param: 'JSON string',
    desc: '原生端返回是否充值成功数据',
  },
  documentPaySuccess: {
    name: 'documentPaySuccess',
    param: 'JSON string',
    desc: '原生端返回文书支付是否成功',
  },
  officeFileCharResult: {
    name: 'officeFileCharResult',
    param: 'JSON string',
    desc: '原生端返回分析选择文件识别的结果',
  },
  _onReceiveNotification: {
    name: '_onReceiveNotification',
    param: 'JSON string',
    desc: '原生端转发消息给移动端',
  },
}

export const isValidFunc = name => {
  return Object.keys(BRIDGE_FUNCTIONS_FOR_NATIVE).includes(name)
}

// 关联bridge方法
export const hookBridgeFunc = (funcObj, hookMethod) => {
  // 判断是否是合法的方法
  const { name } = funcObj
  if (name) {
    window[name] = hookMethod
  }
}
// 释放bridge方法
export const releaseBridgeFunc = funcObj => {
  const { name } = funcObj
  if (name && window[name]) {
    window[name] = null
  }
}

/**
 * @description 使用bridge函数
 * @param {Object} event { name: bridgeName , handler: fn}
 * @param {vm} ctx 组件中的this
 */

export const useBridge = (event, ctx) => {
  const { name, handler } = event
  // 需要验证name的合法性
  const isValidName = isValidFunc(name)
  if (name && handler && isValidName) {
    const fn = (...args) => {
      handler.apply(this, args)
    }
    window[name] = fn
    useEventBus(event, ctx)
  }
}
