const Search = () =>
  import(/* webpackChunkName: "mapp-news-search-pages" */ '@/pages/Search/pages/index')
const precedentDetail = () =>
  import(/* webpackChunkName: "mapp-news-precedentDetail" */ '@/pages/precedentDetail')
const lawDetail = () =>
  import(/* webpackChunkName: "mapp-news-lawDetail" */ '@/pages/Search/pages/lawDetail')
export default [
  {
    path: '/searchKeepAlive/search/old',
    name: 'search',
    component: Search,
    alias: '/search/old',
    meta: {
      fn: 'clickback',
      isPublic: true
    }
  },
  {
    path: '/transform/searchKeepAlive/precedentDetail',
    name: 'searchPrecedentDetail',
    component: precedentDetail
  },
  {
    path: '/transform/searchKeepAlive/lawDetail',
    name: 'searchLawDetail',
    component: lawDetail
  }
]
