<template>
  <div v-if="showInviteDialog" class="new_invite" @click="closeDialog">
    <div class="invite_content">
      <div class="invite_vip_title" @click.stop="handleClick">
        {{ inviteTittle }}
      </div>
      <div class="invite_list">
        <img v-for="(item, index) in passInviteList" :key="index" class="item_avatarUrl" :src="item.avatarUrl" />
        <img class="item_avatarUrl" src="https://oss.farbun.com/5dbb8620c07147ffa046aec42966162f" alt="" />
        <div class="item_plus">+</div>
      </div>
      <div class="btn_area">
        <van-button size="small" type="info" @click="weChatInvite">微信邀请</van-button>
        <van-button size="small" type="info" @click="shortMsgInvite">短信邀请</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
// import { copyTextToClipboard } from '@/utils/assist'
import QRCode from 'qrcodejs2'
export default {
  name: 'new-invite',
  data() {
    return {
      inviteText: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('nonmemberLimit', ['pid']),
    ...mapState('nonmemberLimit', ['inviteList', 'promotionRecordId', 'showInviteDialog']),
    isAddEnterpriseWeChatFriend() {
      return this.userInfo?.isAddEnterpriseWeChatFriend === 1
    },
    // 认证律师的邀请用户
    passInviteList() {
      if (this.inviteList) {
        return this.inviteList.filter((v) => v.isAuthenticationLawyer === 1)
      } else {
        return []
      }
    },
    inviteTittle() {
      console.log(this.passInviteList, '认证律师的邀请用户')
      if (this.passInviteList.lengh === 0) {
        return '邀请两名律师'
      } else if (this.passInviteList.length === 1) {
        return '邀请一位律师'
      } else {
        return '邀请律师'
      }
    },
  },
  methods: {
    ...mapMutations('user', ['SET_USER']),
    ...mapMutations('nonmemberLimit', ['SET_INVITE_LIST', 'SET_INVITE_CODE', 'SET_INVITE_DIALOG_SHOW']),
    handleClick() {},
    // 关闭弹窗
    closeDialog() {
      this.qrcode = null
      this.SET_INVITE_DIALOG_SHOW(false)
    },
    // 获取助力列表
    async getInviteList() {
      try {
        const res = await this.$axios.post(`${this.$fissionBase}/fission/promotionAttentionRecord/list`)
        if (res.data && res.data.code === '200') {
          this.SET_INVITE_LIST(res.data.data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 获取邀请二维码
    async getInviteCode() {
      console.log('获取邀请二维码')
      let promotionRecordId = ''
      const { data } = await this.$axios.post(`${this.$fissionBase}/fission/promotionRecord/generateInvitation`)
      if (data.code === '200' && data.data) {
        promotionRecordId = data.data.promotionRecordId
        this.SET_INVITE_CODE(promotionRecordId)
        this.initQRcode()
      }
    },
    initQRcode() {
      if (this.qrcode) return
      let shareUrl = ''
      if (this.$base === 'https://api.farbun.com') {
        shareUrl = `https://tool.farbun.com/transform/sharevideo?id=${this.promotionRecordId}`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/sharevideo?id=${this.promotionRecordId}`
      }
      // eslint-disable-next-line no-unused-vars
      this.qrcode = new QRCode('qrcodePanel', {
        width: 150,
        height: 150, // 高度
        text: shareUrl, // 二维码内容
      })
      this.inviteText = shareUrl
    },
    weChatInvite() {
      const shareUrl = this.shareUrl
      const name = '您的好友给您推荐了一款律师办公神器。点击查看:'
      console.log('weChatInvite')
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
      } else if (this.ua === this.$ua.i) {
        // ios环境
        // eslint-disable-next-line
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.$notify('网页端暂不支持分享')
        console.log('网页端暂不支持分享', shareUrl)
      }
    },
    // 原生发送短信
    shortMsgInvite() {
      // sms:后面跟收件人的手机号,body后接短信内容
      const text = '您的好友给您推荐了一款律师办公神器。点击查看:' + this.shareUrl
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      if (this.ua === this.$ua.a) {
        window.location.href = 'sms:?body=' + text
      } else if (this.ua === this.$ua.i) {
        window.location.href = 'sms:&body=' + text
      } else {
        window.location.href = 'sms:?body=' + text
        // this.$notify('网页端内容已粘贴到粘贴板上，不支持打开短信功能')
        console.log(text)
      }
    },
  },
  async mounted() {
    if (!this.showInviteDialog) return
    if (!this.inviteList) {
      console.log('nonmember-limit -邀请注册')
      this.getInviteList()
    }
    if (!this.promotionRecordId) {
      await this.getInviteCode()
    } else {
      this.$nextTick(() => {
        this.initQRcode()
      })
    }
  },
}
</script>

<style lang="stylus" scoped>
.new_invite
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  position fixed
  top 0px
  left 0
  z-index 1999
  overflow auto
  .invite_content
    padding 20px
    box-sizing border-box
    position relative
    background #fff
    width 80%
    margin 0 auto
    top 30%
    border-radius 5px
    & .invite_vip_title
      display flex
      align-items center
      justify-content center
      font-weight bold
      color orange
      text-align center
      cursor pointer
    & .invite_list
      display flex
      height 60px
      align-items center
      justify-content center
      & .item_plus
        width 40px
        height 40px
        border-radius 50%
        font-size 30px
        text-align center
        line-height 40px
        color #666666
        border 1px solid #666666
        margin-left 10px
      & .item_avatarUrl
        width 40px
        height 40px
        border-radius 50%
        border 1px solid #ccc
        margin 0 10px 0
        overflow hidden
        box-sizing border-box
</style>
