/* eslint-disable no-new */
import { caseBase } from '@/api/config'
const qiniu = require('qiniu-js')
let axios = null
let store = null
const UploadFile = (function() {
  function getToken(type, filename, withId) {
    if (!withId) {
      return this.$axios.get(
        `${caseBase}/management/oss/token?advice=${type}&model=${window.encodeURIComponent(
          JSON.stringify({
            userId: this.$store.state.user.userInfo.id,
            fileName: filename,
          })
        )}`
      )
    } else {
      return this.$axios.get(
        `${caseBase}/management/oss/token?advice=${type}&userId=${
          this.$store.state.user.userInfo.id
        }&fileName=${filename}`
      )
    }
  }
  return function(type, file, callback, fn, withId = false) {
    getToken.call(this, type, file.name, withId).then(res => {
      const key = withId ? res.data.data.key : res.data.data.key + file.name
      const observable = qiniu.upload(
        file, // 需要上传的文件
        key, // 文件资源名
        res.data.data.token, // Token
        {
          // putExtra
          fname: file.name,
          mimeType: null,
        },
        {
          // config
          useCdnDomain: true,
          region: qiniu.region.z1,
          domain: res.data.data.bucket,
        }
      )
      const complete = callback.complete
      const next = callback.next
      let subscription = null
      const _self = this
      callback.complete = function() {
        complete(arguments)
        if (fn) {
          fn(file, key)
        }
      }
      callback.next = function() {
        if (_self._flag) {
          subscription.unsubscribe()
        }
        next(...arguments)
      }
      subscription = observable.subscribe(callback)
    })
  }
})()

Upload.prototype.preventUpload = function() {
  this._flag = true
}

Upload.prototype.UploadFile = function(type, file, callback, fn, withId) {
  UploadFile.call(this, type, file, callback, fn, withId)
}

// 默认文件存储
Upload.prototype.UploadFile.Default = function(file, callback, fn) {
  UploadFile.call(this, 'DEFAULT', file)
}

// 用户头像
Upload.prototype.UploadFile.icon = function(file, callback, fn) {
  UploadFile.call(this, 'USER_ICON', file)
}

// 用户案件报告
Upload.prototype.UploadFile.case_report = function(file, callback, fn) {
  UploadFile.call(this, 'USER_LEGAL_CASE_REPORT', file)
}

// 用户案件
Upload.prototype.UploadFile.legal = function(file, callback, fn) {
  UploadFile.call(this, 'LEGAL_CASE', file)
}

// 含义不清淅
Upload.prototype.UploadFile.task = function(file, callback, fn) {
  UploadFile.call(this, 'TASK', file)
}

// 设置必备参数
Upload.prototype.setParams = function(_axios, _store) {
  axios = _axios
  store = _store
}
function Upload() {
  this.$axios = axios
  this.$store = store
  this._flag = false
}
export default Upload
